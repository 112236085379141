import { Mail_01, Monitor_01, PuzzlePiece_01, User_01, Users_01 } from '@recruitrobin/robin-theme/web-icons';
import { UserRoles } from 'clients/UserClient/types';
import { Typography } from 'components/ui/atoms';
import { routes } from 'config';
import { useContext } from 'react';
import { CandidateOutreachModalContext } from 'shared/contexts/CandidateOutreachModalContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useContextSelector } from 'use-context-selector';
import { useContainer } from './Sidebar.useContainer';
import { useStyles } from './SidebarNav.styles';
import { SidebarNavPages } from './SidebarNav.types';
import { useSelectedRoute } from './SidebarNav.useSelectedRoute';
import SidebarNavItemMenuSettings from './SidebarNavItemMenuSettings';

const SIDEBAR_MENU_ICONS_SIZE = 20;

const SidebarNavSettingsMenu = () => {
  const classes = useStyles();
  const { navigateTo } = useContainer();
  const { dictionary } = useContext(LocalizationContext);
  const activeRoute = useSelectedRoute();
  const { currentUser } = useContext(UserContext);
  const setCandidateOutreachModal = useContextSelector(
    CandidateOutreachModalContext,
    (s) => s.setCandidateOutreachModal,
  );

  const closeOutreachModalOnClick = (callback: () => void) => {
    setCandidateOutreachModal(null);
    callback();
  };

  return (
    <div css={classes.settingsContent}>
      <div css={classes.header}>
        <Typography variant={(v) => v.heading[5]} colorv2={(c) => c.neutral.variant[70]}>
          {dictionary.settings}
        </Typography>
      </div>

      <div css={classes.divider} />

      {currentUser?.role !== UserRoles.Recruiter && (
        <>
          <SidebarNavItemMenuSettings
            onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.users))}
            icon={<User_01 size={SIDEBAR_MENU_ICONS_SIZE} />}
            label={dictionary.users}
            selected={SidebarNavPages.Users === activeRoute}
          />
          <SidebarNavItemMenuSettings
            onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.teams))}
            icon={<Users_01 size={SIDEBAR_MENU_ICONS_SIZE} />}
            label={dictionary.teams}
            selected={SidebarNavPages.Teams === activeRoute}
          />
        </>
      )}
      <SidebarNavItemMenuSettings
        onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.emailSettings))}
        icon={<Mail_01 size={SIDEBAR_MENU_ICONS_SIZE} />}
        label={dictionary.emailSettings}
        selected={SidebarNavPages.EmailSettings === activeRoute}
      />

      {currentUser?.role !== UserRoles.Recruiter && (
        <SidebarNavItemMenuSettings
          onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.atsConnections))}
          icon={<Monitor_01 size={SIDEBAR_MENU_ICONS_SIZE} />}
          label={dictionary.ats}
          selected={SidebarNavPages.AtsConnections === activeRoute}
        />
      )}
      <SidebarNavItemMenuSettings
        onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.browserExtension))}
        icon={<PuzzlePiece_01 size={SIDEBAR_MENU_ICONS_SIZE} />}
        label={dictionary.browserExtension}
        selected={SidebarNavPages.BrowserExtension === activeRoute}
      />
    </div>
  );
};

export default SidebarNavSettingsMenu;
