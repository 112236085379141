import { Building_01, Lock_01, Mail_01, User_01 } from '@recruitrobin/robin-theme/web-icons';
import { Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { AvailableCredits } from 'components/ui/molecules/AvailableCredits/AvailableCredits';
import { generateDtiAttribute } from 'helpers/helpers';
import { useGTM } from 'hooks/gtm';
import { Fragment, useContext, useState } from 'react';
import { DropDownMenu } from 'shared/components/layouts/DropDownMenu/DropDownMenu';
import UserAvatar from 'components/ui/molecules/UserAvatar';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { ChangePassword } from './components/ChangePassword/ChangePassword';
import { EditUserProfile } from './components/EditUserProfile/EditUserProfile';
import { useStyles } from './styles';

export const UserMenu = () => {
  const classes = useStyles();
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const gtm = useGTM();

  const { logout, currentUser } = useContext(UserContext);
  const currentUserFirstName = currentUser?.first_name || '';
  const currentUserLastName = currentUser?.last_name || '';

  const { dictionary } = useContext(LocalizationContext);

  return (
    <Fragment>
      <DropDownMenu
        css={classes.root}
        label={<UserAvatar initials={`${currentUserFirstName.slice(0, 1)}${currentUserLastName.slice(0, 1)}`} />}
        closeOnClick={false}
        onClick={gtm.openedUserOverview}
        options={({ setOpen, Item }) => (
          <div css={classes.content}>
            <Item {...generateDtiAttribute('first-name-item')}>
              <Mail_01 size={24} color={(c) => c.neutral.variant[80]} />
              <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[80]}>
                {currentUser?.first_name}
              </Typography>
            </Item>
            <Item {...generateDtiAttribute('tenant-code-item')}>
              <Building_01 size={24} color={(c) => c.neutral.variant[80]} />
              <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[80]}>
                {currentUser?.tenant.name}
              </Typography>
            </Item>
            <Item
              onClick={() => {
                gtm.openedUserProfileMenu();
                return [setOpenEditProfile(true), setOpen(false)];
              }}
              {...generateDtiAttribute('profile-item')}>
              <User_01 size={24} color={(c) => c.neutral.variant[80]} />
              <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[80]}>
                {dictionary.profile}
              </Typography>
            </Item>
            <Item
              onClick={() => [setOpenChangePassword(true), setOpen(false)]}
              {...generateDtiAttribute('change-password-item')}>
              <Lock_01 size={24} color={(c) => c.neutral.variant[80]} />
              <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[80]} nowrap>
                {dictionary.changePassword}
              </Typography>
            </Item>
            <Item {...generateDtiAttribute('available-credits-item')}>
              <AvailableCredits />
            </Item>
            <Item style={{ justifyContent: 'center', border: 0 }} {...generateDtiAttribute('logout-item')}>
              <Button onClick={logout} variant="highlight" label={dictionary.logout} data-tutorial-id="logout" />
            </Item>
          </div>
        )}
        {...generateDtiAttribute('dropdown-user-menu')}
      />
      <EditUserProfile open={openEditProfile} setOpen={setOpenEditProfile} />
      <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} />
    </Fragment>
  );
};
