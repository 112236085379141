import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// @ts-ignore
import { FormProvider, useForm } from 'react-hook-form';
import { Dialog } from '@material-ui/core';

import { routes } from 'config';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { ResetPasswordContext } from 'shared/contexts/ResetPasswordContext';
import { Button } from 'components/ui/molecules';
import { SmallLanguageSwitch } from 'shared/components/localization/SmallLanguageSwitch/SmallLanguageSwitch';
import { CheckCircle } from 'shared/icons/index';

import { useStyles } from './ResetPassword.styles';
import { paramBind } from 'helpers/useQueryHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { userClient } from 'clients/UserClient/UserClient';
import { TextFieldWithUseForm } from 'shared/components/TextField';
import { Column, Expand } from 'components/ui/atoms';
import { RobinLogo } from '@recruitrobin/robin-theme/web-icons';
import { BaseCallbackError } from 'clients/UserClient/types';
import { ClipLoader } from 'react-spinners';
import { useLocalStorage } from 'hooks/shared';

type FormFields = {
  password?: string;
  repeatPassword?: string;
};
export const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const [userId, resetCode, tenantId] = [
    parseInt(params.get('userId') || ''),
    params.get('resetCode') || '',
    parseInt(params.get('tenantId') || ''),
  ];
  const [showDialog, setShowDialog] = useState(false);
  const { authentication } = useContext(ResetPasswordContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setValue } = useLocalStorage('first_login');

  useEffect(() => {
    setValue(true);
  }, []);

  const { status } = useQuery(
    ['validateResetCode', { user_id: userId, reset_code: resetCode, tenant_id: tenantId }],
    paramBind(userClient.validatePasswordResetCode),
    { enabled: Boolean(resetCode) },
  );

  const isValidLink = status === 'success' || !!authentication;

  const methods = useForm<FormFields>({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });
  const { handleSubmit, watch, setError } = methods;

  const newPassword = watch('password');

  const onSubmit = ({ password }: { password: string }) => {
    setIsLoading(true);
    const data = {
      reset_code: resetCode,
      password,
      user_id: userId,
      tenant_id: tenantId,
    };

    try {
      if (authentication) {
        const dataWithAuthentication = {
          id: authentication.user_id,
          should_change_password: false,
          password,
        };

        return userClient
          .updateWithToken(dataWithAuthentication, authentication.access_token)
          .then(() => setShowDialog(true));
      }

      userClient
        .resetPassword(data)
        .then(() => {
          setShowDialog(true);
        })
        .catch((e) => {
          const { response } = e as BaseCallbackError;
          if (response?.data.detail === 'PASSWORD_SAME_AS_OLD') {
            setError('password', { type: 'error', message: dictionary.passwordIsTheSame });
            return;
          }
          setError('password', { type: 'error', message: dictionary.invalidPassword });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <Column center alignCenter>
        <RobinLogo width={220} />
        <Expand height={50} />
        <div css={classes.title}>{isValidLink ? dictionary.resetPassword : dictionary.invalidResetLink}</div>
        <div css={classes.description}>
          {isValidLink ? dictionary.pleaseEnterYourNewPassword : dictionary.invalidResetLinkDescription}
        </div>
        {isValidLink && (
          <FormProvider {...methods}>
            <form css={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <TextFieldWithUseForm
                label={dictionary.newPassword}
                type="password"
                name="password"
                rules={{
                  required: dictionary.required,
                  minLength: {
                    value: 12,
                    message: dictionary.atLeast12Characters,
                  },
                  validate: (value: any) => {
                    if (!/[0-9]/.test(value)) {
                      return dictionary.passwordMustContainDigit;
                    }

                    if (!/[A-Z]/.test(value)) {
                      return dictionary.passwordMustContainUppercase;
                    }

                    if (!/[a-z]/.test(value)) {
                      return dictionary.passwordMustContainLowercase;
                    }
                  },
                }}
                contained
              />
              <hr />
              <TextFieldWithUseForm
                type="password"
                name="repeatPassword"
                label={dictionary.repeatNewPassword}
                rules={{
                  required: dictionary.required,
                  validate: (value: any) => value === newPassword || dictionary.passwordsDoNotMatch,
                }}
                contained
              />
              <Button
                label={dictionary.save}
                submit
                icon={isLoading ? <ClipLoader color="currentColor" size={18} /> : undefined}
              />
            </form>
          </FormProvider>
        )}
        <SmallLanguageSwitch />
        <Dialog open={showDialog} onClose={() => navigate(routes.login)}>
          <div css={classes.popup}>
            <div css={classes.popupIcon}>
              <CheckCircle variant="tertiary" />
            </div>
            <div css={classes.popupTitle}>{dictionary.passwordResetComplete}</div>
            <div css={classes.popupDescription}>
              <div>{dictionary.goToLoginDescription}</div>
              <Button onClick={() => navigate(routes.login)} label={dictionary.goToLogin} />
            </div>
          </div>
        </Dialog>
      </Column>
    </div>
  );
};
