import type { IndeedLicenseModalProps } from './IndeedLicenseModal.types';

import { Button, Dialog, Loader, Typography } from '@recruitrobin/robin-theme/web-components';
import { Indeed, Question } from '@recruitrobin/robin-theme/web-icons';
import { useContext, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './IndeedLicenseModal.styles';
import { useTexts } from './IndeedLicenseModal.useTexts';
import { IndeedLicenseModalIcon } from './IndeedLicenseModalIcon';

const LOGIN_TO_INDEED_LINK = 'https://secure.indeed.com/auth';
const READ_MORE_LINK = 'https://intercom.help/recruit-robin';

export const IndeedLicenseModal = ({
  type,
  onRequestLogin,
  onRequestClose,
  isVisible,
  assignedEmail = '',
  loggedInEmail = '',
  availableEmails = [],
}: IndeedLicenseModalProps) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();
  const texts = useTexts({ type, assignedEmail, loggedInEmail, availableEmails });

  const handleOpenLink = (link: string) => {
    if (link === LOGIN_TO_INDEED_LINK) {
      setIsLoggingIn(true);
      onRequestLogin().finally(() => setIsLoggingIn(false));
      return;
    }

    window.open(link, '_blank', 'noopener');
  };

  const Buttons = () => {
    let label;
    let icon;
    let link;

    switch (texts.buttonStatus) {
      case 'login':
        label = dictionary.loginToIndeed;
        icon = Indeed;

        if (isLoggingIn) {
          icon = () => <Loader size={18} />;
        }

        link = LOGIN_TO_INDEED_LINK;
        break;
      case 'info':
        label = dictionary.readMore;
        icon = Question;
        link = READ_MORE_LINK;
    }

    return (
      <div css={classes.buttons}>
        <Button label={dictionary.close} style="text" type="subtle2" onClick={onRequestClose} />
        <Button label={label} style="tonal" type="highlight" onClick={() => handleOpenLink(link)} startIcon={icon} />
      </div>
    );
  };

  return (
    <Dialog
      maxWidth={800}
      isVisible={isVisible}
      body={
        <div css={classes.body}>
          <IndeedLicenseModalIcon type={texts.iconStatus} />

          <Typography variant={(v) => v.heading[3]} color={(c) => c.neutral.variant[80]}>
            {texts.title}
          </Typography>

          <Typography variant={(v) => v.body.long} color={(c) => c.neutral.variant[70]}>
            {texts.description}
          </Typography>
        </div>
      }
      renderButtons={<Buttons />}
    />
  );
};
