import type { UserResponse } from 'clients/UserClient/types';

import { useQuery } from '@tanstack/react-query';
import { useIndeedConfig } from 'shared/hooks/useBrowserExtension/indeed/useIndeedConfig';
import { useCurrentUserQuery } from 'shared/hooks/useCurrentUserQuery';
import { QueryKey } from 'types/query';

type IndeedLicense = UserResponse['indeed_license'];

export const useActiveIndeedLicenseQuery = () => {
  const { data: currentUser } = useCurrentUserQuery();
  const indeedConfig = useIndeedConfig();

  return useQuery<IndeedLicense | null>(
    [QueryKey.useActiveIndeedLicenseQuery, { currentUser, indeedConfig }],
    async () => {
      if (
        indeedConfig.isLoggedIn &&
        currentUser?.indeed_license?.indeed_license_id &&
        indeedConfig.email === currentUser?.indeed_license?.indeed_license_id
      ) {
        return currentUser.indeed_license;
      }

      return null;
    },
    { enabled: !indeedConfig.isInitialLoading },
  );
};
