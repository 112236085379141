import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { queryClient } from 'config/queryClient';
import { useActiveIndeedLicenseQuery } from 'hooks/shared/useActiveIndeedLicenseQuery';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { QueryKey } from 'types/query';

export const useCampaign = (id: number | undefined) => {
  const activeIndeedLicense = useActiveIndeedLicenseQuery();

  const enabled = typeof id === 'number' && activeIndeedLicense.data !== undefined;

  const { data: campaign, isLoading } = useQuery(
    [QueryKey.campaignManagerContextUseCampaign, id],
    async () => {
      if (!id) throw Error('Campaign id not defined');

      const { data: campaign } = await campaignsClient.getCampaign(id);

      if (campaign.current_filter.filters.sources.length === 0) {
        campaign.current_filter.filters.sources = Object.values(CampaignJobboard);
      }

      return campaign;
    },
    { enabled, keepPreviousData: enabled },
  );

  return { campaign, isFetching: enabled && isLoading };
};

export const setCampaign = (id: number | undefined, cb: (data: CampaignResponse) => CampaignResponse) => {
  queryClient.setQueryData<CampaignResponse>([QueryKey.campaignManagerContextUseCampaign, id], (data) => {
    if (!data) throw Error('Campaign is undefined');

    return cb(data);
  });
};
