import { Indeed, Link_03, LinkBroken } from '@recruitrobin/robin-theme/web-icons';
import { useStyles } from './IndeedLicenseModalIcon.styles';

export const IndeedLicenseModalIcon = ({ type }: { type: 'success' | 'error' }) => {
  const classes = useStyles();

  return (
    <div css={[classes.root, type === 'success' && classes.rootSuccess, type === 'error' && classes.rootError]}>
      <Indeed height={26} />

      <div css={[classes.badge, type === 'success' && classes.badgeSuccess, type === 'error' && classes.badgeError]}>
        {type === 'success' && <Link_03 size={14} />}
        {type === 'error' && <LinkBroken size={14} />}
      </div>
    </div>
  );
};
