import { generateTransitionStyles } from 'styles/theme';

import { RadioStyleState } from './types';
import { Styles, Theme, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const baseIcon = ({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '16px',
  height: '16px',
  padding: '1px',
  borderRadius: '50%',
  color: theme.colors.neutral.variant[0],
  border: `2px solid ${theme.colors.neutral.variant[30]}`,
  backgroundColor: theme.colors.neutral.variant[10],
});

const styles = {
  root: ({ theme, props }) =>
    css({
      '.radio--icon': {
        transition: generateTransitionStyles(['background-color', 'border-color', 'color']),
        '&:not(.radio--icon-active)': {
          borderColor: theme.colors.neutral.variant[30],
          backgroundColor: theme.colors.neutral.variant[0],
          ...(props.error && {
            borderColor: theme.colors.tertiary.red[60],
          }),
        },
      },
      '&.MuiFormControlLabel-root': {
        alignItems: 'flex-start',
        '.MuiFormControlLabel-label': {
          padding: '9px 9px 9px 0',
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
      '.MuiButtonBase-root.MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
      '.radio--icon.radio--icon-active': {
        borderColor: theme.colors.primary.fuchsia[40],
        ...(props.error && {
          borderColor: theme.colors.tertiary.red[60],
        }),
      },
      '&:hover': {
        '.radio--icon:not(.radio--icon-active)': {
          borderColor: theme.colors.neutral.variant[40],
          ...(props.error && {
            borderColor: theme.colors.tertiary.red[40],
          }),
        },
      },
      '&:hover .Mui-focusVisible, .Mui-focusVisible': {
        '.radio--icon': {
          borderColor: theme.colors.primary.fuchsia[30],
          ...(props.error && {
            borderColor: theme.colors.tertiary.red[50],
          }),
        },
      },
      ...(props.disabled && {
        '&& .radio--icon, &:hover .radio--icon:not(.radio--icon-active), &:hover .radio--icon, .radio--icon': {
          borderColor: theme.colors.neutral.variant[10],
          backgroundColor: theme.colors.neutral.variant[10],
        },
      }),
    }),
  icon: ({ theme }) =>
    css({
      ...baseIcon({ theme }),
    }),
  checkedIcon: ({ theme, props }) =>
    css({
      ...baseIcon({ theme }),
      '.Mui-focusVisible, .Mui-focusVisible &.radio--icon-active': {
        '& > .radio--icon-circle': {
          backgroundColor: theme.colors.primary.fuchsia[60],
          ...(props.error && {
            backgroundColor: theme.colors.tertiary.red[40],
          }),
        },
      },
      '&.radio--icon-active': {
        backgroundColor: props.error ? theme.colors.tertiary.red[60] : theme.colors.primary.fuchsia[40],
      },
    }),
} satisfies Styles<RadioStyleState>;

export const useStyles = withStyles(styles);
