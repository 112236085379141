import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CANDIDATE_STATUS } from 'services/candidates';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useMatch = (params: WrappedHookParams) => {
  return useCandidateAction<MatchHookCallbackParams>(
    async ({ candidate, campaignId }) => {
      await campaignsClient.createOrUpdateCampaignCandidate({
        campaignId: campaignId ?? candidate.campaignId,
        esPersonId: candidate.es_person_id,
        status: CANDIDATE_STATUS.MATCHED,
        is_unlocked: candidate.isUnlocked,
      });
    },
    {
      onSuccess: ({ fcParams: { candidate, campaignId }, gtm }) => {
        gtm.matchCandidate(campaignId ?? candidate.campaignId, candidate.es_person_id);

        return async ({ candidateOrchestrator: co, pageContext }) => {
          if (pageContext === PageContext.Campaign) {
            if (candidate.status === CANDIDATE_STATUS.NEW) {
              co.campaign.decreaseStatOf.currentJobBoard();
            }

            co.campaign.remove.fromCurrentList();
            co.campaign.reset.matchList();
            co.campaign.decreaseStatOf.currentList();
            co.campaign.increaseStatOf.matchList();
          } else if (pageContext === PageContext.RatedCandidates) {
            co.ratedCandidates.remove.fromCurrentList();
            co.ratedCandidates.reset.matchList();
            co.ratedCandidates.decreaseStatOf.currentList();
            co.ratedCandidates.increaseStatOf.matchList();
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            co.share.moveCandidateTo.matchList();
          }
        };
      },
    },
  )(params);
};

type MatchHookCallbackParams = {
  campaignId?: number;
};
