import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { useGetNewIndeedMessages } from './useUnreadIndeedMessages.hooks';
import { useEffect } from 'react';

export const useUnreadIndeedMessages = () => {
  const { newIndeedMessages } = useGetNewIndeedMessages();

  useEffect(() => {
    if (newIndeedMessages.length > 0)
      notificationsClient.saveIndeedMessagesNotifications({ messages: newIndeedMessages });
  }, [newIndeedMessages]);
};
