export enum IndeedLicenseModalType {
  indeedSubscriptionConnected = 'indeedSubscriptionConnected',
  noIndeedSubscriptionConnected = 'noIndeedSubscriptionConnected',
  indeedSubscriptionAvailable = 'indeedSubscriptionAvailable',
  noIndeedSubscriptionAvailable = 'noIndeedSubscriptionAvailable',
  indeedSubscriptionGranted = 'indeedSubscriptionGranted',
  indeedSubscriptionWithdrawn = 'indeedSubscriptionWithdrawn',
  indeedSubscriptionWithdrawnSubscriptionAvailable = 'indeedSubscriptionWithdrawnSubscriptionAvailable',
  indeedSubscriptionConnectedAdmin = 'indeedSubscriptionConnectedAdmin',
  noIndeedSubscriptionConnectedAdmin = 'noIndeedSubscriptionConnectedAdmin',
  indeedSubscriptionExpired = 'indeedSubscriptionExpired',
  indeedSubscriptionExpiredWithAvailable = 'indeedSubscriptionExpiredWithAvailable',
  noIndeedActiveSubscription = 'noIndeedActiveSubscription',
  noIndeedActiveSubscriptionWithAvailable = 'noIndeedActiveSubscriptionWithAvailable',
}

export type IndeedLicenseModalProps = {
  isVisible: boolean;
  onRequestLogin: () => Promise<void>;
  onRequestClose: () => void;
  assignedEmail?: string;
  loggedInEmail?: string;
  availableEmails?: string[];
  type: IndeedLicenseModalType;
};

export enum IndeedAssignmentStatus {
  Success = 'Success',
  Error = 'Error',
}
