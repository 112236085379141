import { LoggerBase } from './loggerBase';
import { LOG_LEVEL, LogCustomAttribute, LoggerInfoKeys, LoggerProps } from './types';
import { withLogger } from './utils';

export class Logger extends LoggerBase {
  constructor(props: LoggerProps = {}) {
    super(props);
  }

  setCustomAttribute = (name: LogCustomAttribute, value: newrelic.SimpleType) => {
    this.safeCall(() => {
      withLogger({ debug: this.debug, level: LOG_LEVEL.INFO }, newrelic.setCustomAttribute, name, value);
    });
  };

  info = (arg: string | Record<string, any>, key?: LoggerInfoKeys) => {
    this.safeCall(() => {
      this.log(LOG_LEVEL.INFO, arg, key);
    });
  };

  error = (e: any, customAttributes?: Record<string, newrelic.SimpleType>) => {
    const error = typeof e === 'string' || e instanceof Error ? e : JSON.stringify(e);

    this.safeCall(() => {
      withLogger({ debug: this.debug, level: LOG_LEVEL.ERROR }, newrelic.noticeError, error, customAttributes);
    });
  };
}
