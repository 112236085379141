import { IconButton } from '@recruitrobin/robin-theme/web-components';
import { routes } from 'config';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useBrowserExtension } from 'shared/hooks';
import { useIndeedConfig } from 'shared/hooks/useBrowserExtension/indeed/useIndeedConfig';
import { useContextSelector } from 'use-context-selector';
import useCallWerknlModal from './CandidateOutreachButton.useCallWerknlModal';
import useOutreachSettings from './CandidateOutreachButton.useOutreachSettings';
import { OutreachTemplateToEditorType } from './types';

const useContainer = () => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const {
    isVisible: isWerknlModalVisible,
    onConfirm: onConfirmWerknlModal,
    callWerknlModal,
    isWerknlActive,
  } = useCallWerknlModal();
  const availableOutreachMethods = useContextSelector(CandidateContext, (state) => state.availableOutreachMethods);
  const setShouldShowOutreachModal = useContextSelector(CandidateContext, (s) => s.setShouldShowOutreachModal);
  const { linkedinStatus } = useBrowserExtension();
  const { isLoggedIn: isIndeedLoggedIn } = useIndeedConfig();
  const { outreachMethodsSettings } = useOutreachSettings();

  const setActiveOutreachModal = useCallback(
    (option: string) => setShouldShowOutreachModal(OutreachTemplateToEditorType[option]),
    [setShouldShowOutreachModal],
  );

  const isLinkedinLoggedIn = linkedinStatus === 'Logged In';

  const onClickOutreachMethod = useCallback(
    (option: string, e: React.MouseEvent<HTMLElement>) => {
      if (option === dictionary.indeed && !isIndeedLoggedIn) {
        navigate(routes.browserExtension);
        return;
      }
      if (option === dictionary.linkedIn && !isLinkedinLoggedIn) {
        navigate(routes.browserExtension);
        return;
      }
      if (option === dictionary.linkedInInMail && !isLinkedinLoggedIn) {
        navigate(routes.browserExtension);
        return;
      }
      if (option === dictionary.Werknl) {
        callWerknlModal();
        !isWerknlActive && e.stopPropagation();

        return;
      }

      setActiveOutreachModal(option);
    },
    [dictionary, isWerknlActive, isIndeedLoggedIn, isLinkedinLoggedIn, callWerknlModal, setActiveOutreachModal],
  );

  const availableOutreachMethodsButtons = useMemo(
    () =>
      availableOutreachMethods.map((outreachMethod) => (
        <IconButton
          onClick={(e) => {
            onClickOutreachMethod(outreachMethodsSettings[outreachMethod].label, e);
          }}
          key={outreachMethod}
          icon={outreachMethodsSettings[outreachMethod].startIcon()}
          style={outreachMethodsSettings[outreachMethod].active ? 'hover' : 'default'}
          type={outreachMethodsSettings[outreachMethod].active ? 'success' : 'subtle'}
          label={
            outreachMethodsSettings[outreachMethod].active
              ? outreachMethodsSettings[outreachMethod].label
              : outreachMethodsSettings[outreachMethod].whyNotEnabled
          }
        />
      )),
    [availableOutreachMethods, outreachMethodsSettings, onClickOutreachMethod],
  );

  return {
    isWerknlModalVisible,
    onConfirmWerknlModal,
    availableOutreachMethodsButtons,
  };
};

export default useContainer;
