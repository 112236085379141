import { logger } from 'config/logger';
import { ExtensionNotInstalledException, UnsupportedBrowserException } from 'shared/exceptions';
import {
  IndeedBadUserInputException,
  IndeedException,
  IndeedServiceResultNullException,
} from 'shared/exceptions/IndeedException';
import {
  LinkedinCSRFTokenCheckFailedException,
  LinkedinException,
  LinkedinFreeInviteLimitReachedException,
  LinkedinMaximumInviteLimitReachedException,
} from 'shared/exceptions/LinkedinException';

const sendMessage = async <T = any>(key: string, param?: any): Promise<T> => {
  const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID || '';

  return new Promise<any>((resolve, reject) => {
    try {
      if (!chrome) throw new UnsupportedBrowserException();
      if (!chrome.runtime) throw new ExtensionNotInstalledException();

      chrome.runtime.sendMessage(extensionId, { key, data: param }, async (response) => {
        try {
          const message = await onReceiveMessage(param?.url, response);

          resolve(message);
        } catch (e) {
          reject(e);
        }
      });
    } catch (e) {
      let error;

      if (isUnsupportedBrowserError(e)) {
        error = new UnsupportedBrowserException();
      } else if (e instanceof Error) {
        error = e;
      }

      reject(error || { error: 'error', status: 'Error', message: e });
    }
  }).catch((error) => onSendMessageError(error, param) ?? {});
};

const onReceiveMessage = (url = '', response: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      LinkedinException.checkAndThrowError(response);
      IndeedException.checkAndThrowError(response, { data: { url } });

      if (response && (response.status === 500 || response.status === 403)) {
        throw { ...response };
      }

      resolve(response ?? {});
    } catch (err) {
      reject(err);
    }
  });
};

const onSendMessageError = (error: unknown, param?: any) => {
  if (
    [
      LinkedinFreeInviteLimitReachedException,
      LinkedinMaximumInviteLimitReachedException,
      IndeedBadUserInputException,
    ].some((err) => error instanceof err)
  ) {
    throw error;
  }

  if (
    [
      UnsupportedBrowserException,
      ExtensionNotInstalledException,
      LinkedinCSRFTokenCheckFailedException,
      IndeedServiceResultNullException,
    ].some((err) => error instanceof err)
  ) {
    logger.error(error);

    if (error instanceof LinkedinCSRFTokenCheckFailedException || error instanceof IndeedServiceResultNullException) {
      return { status: 403 };
    }
  } else {
    if (error instanceof Error) {
      logger.error({
        error: { name: error.name, message: error.message, stack: error.stack, cause: error.cause },
        data: param,
      });
    } else {
      logger.error({ error, data: param });
    }
  }
};

const isUnsupportedBrowserError = (e: unknown) => {
  return (
    e instanceof ReferenceError &&
    (e.message === 'chrome is not defined' || e.message === "Can't find variable: chrome")
  );
};

export const browserExtensionClient = {
  sendMessage,
};
