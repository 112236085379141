import type { GlobalModalsState } from './GlobalModalsContext.types';

import { createContext } from 'use-context-selector';
import { notImplemented } from 'utils/notImplemented';

const initialState: GlobalModalsState = {
  indeedModalHandlers: {
    close: notImplemented,
    open: notImplemented,
    validateIndeedStatus: notImplemented,
  },
};

export const GlobalModalsContext = createContext(initialState);
