import { useState } from 'react';
import { createContext } from 'use-context-selector';
import { LandingPageContextType } from './LandingPageContext.types';

const initialState: LandingPageContextType = {
  isShowingLandingPage: false,
  setIsShowingLandingPage: () => {
    throw Error('not implemented');
  },
};

export const LandingPageContext = createContext<LandingPageContextType>(initialState);

export const LandingPageProvider = ({ children }: { children: React.ReactNode }) => {
  const [isShowingLandingPage, setIsShowingLandingPage] = useState(false);

  const value = {
    isShowingLandingPage,
    setIsShowingLandingPage,
  };
  return <LandingPageContext.Provider value={value}>{children}</LandingPageContext.Provider>;
};
