import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css`
    position: relative;
    z-index: 0;
  `,
  disabledReason: css`
    & > span {
      display: inline-flex;
      text-align: center;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
