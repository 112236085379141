import { Typography } from 'components/ui/atoms';
import { useStyles } from './SidebarNavItemMenuSettings.styles';
import { SidebarNavItemMenuSettingsProps } from './SidebarNavItemMenuSettings.types';

const SidebarNavItemMenuSettings = ({ onClick, icon, label, selected }: SidebarNavItemMenuSettingsProps) => {
  const classes = useStyles();

  return (
    <div css={[classes.menuOptionSettings, selected && classes.selected]} onClick={onClick}>
      {icon}
      <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[70]}>
        {label}
      </Typography>
    </div>
  );
};

export default SidebarNavItemMenuSettings;
