import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { IndeedLicenseModalType } from './IndeedLicenseModal.types';

export const useTexts = ({
  type,
  assignedEmail,
  loggedInEmail,
  availableEmails,
}: {
  type: IndeedLicenseModalType;
  assignedEmail: string;
  loggedInEmail: string;
  availableEmails: string[];
}) => {
  const { dictionary } = useContext(LocalizationContext);

  return useMemo(() => {
    let buttonStatus: 'login' | 'info';
    let iconStatus: 'success' | 'error';
    let title;
    let description;

    switch (type) {
      case IndeedLicenseModalType.indeedSubscriptionConnected:
        buttonStatus = 'info';
        iconStatus = 'success';
        title = dictionary.indeedSubscriptionConnectedTitle;
        description = dictionary.indeedSubscriptionConnectedDescription(assignedEmail);
        break;
      case IndeedLicenseModalType.noIndeedSubscriptionConnected:
        buttonStatus = 'login';
        iconStatus = 'error';
        title = dictionary.noIndeedSubscriptionConnectedTitle;
        description = dictionary.noIndeedSubscriptionConnectedDescription;
        break;
      case IndeedLicenseModalType.indeedSubscriptionAvailable:
        buttonStatus = 'login';
        iconStatus = 'error';
        title = dictionary.indeedSubscriptionAvailableTitle;
        description = dictionary.indeedSubscriptionAvailableDescription(availableEmails);
        break;
      case IndeedLicenseModalType.noIndeedSubscriptionAvailable:
        buttonStatus = 'info';
        iconStatus = 'error';
        title = dictionary.noIndeedSubscriptionAvailableTitle;
        description = dictionary.noIndeedSubscriptionAvailableDescription;
        break;
      case IndeedLicenseModalType.indeedSubscriptionGranted:
        buttonStatus = 'login';
        iconStatus = 'success';
        title = dictionary.indeedSubscriptionGrantedTitle;
        description = dictionary.indeedSubscriptionGrantedDescription(assignedEmail);
        break;
      case IndeedLicenseModalType.indeedSubscriptionWithdrawn:
        buttonStatus = 'info';
        iconStatus = 'error';
        title = dictionary.indeedSubscriptionWithdrawnTitle;
        description = dictionary.indeedSubscriptionWithdrawnDescription(assignedEmail);
        break;
      case IndeedLicenseModalType.indeedSubscriptionWithdrawnSubscriptionAvailable:
        buttonStatus = 'login';
        iconStatus = 'error';
        title = dictionary.indeedSubscriptionWithdrawnSubscriptionAvailableTitle;
        description = dictionary.indeedSubscriptionWithdrawnSubscriptionAvailableDescription(
          assignedEmail,
          availableEmails,
        );
        break;
      case IndeedLicenseModalType.indeedSubscriptionConnectedAdmin:
        buttonStatus = 'info';
        iconStatus = 'success';
        title = dictionary.indeedSubscriptionConnectedAdminTitle;
        description = dictionary.indeedSubscriptionConnectedAdminDescription(assignedEmail);
        break;
      case IndeedLicenseModalType.noIndeedSubscriptionConnectedAdmin:
        buttonStatus = 'login';
        iconStatus = 'error';
        title = dictionary.noIndeedSubscriptionConnectedAdminTitle;
        description = dictionary.noIndeedSubscriptionConnectedAdminDescription;
        break;
      case IndeedLicenseModalType.indeedSubscriptionExpired:
        buttonStatus = 'info';
        iconStatus = 'error';
        title = dictionary.indeedSubscriptionExpiredTitle;
        description = dictionary.indeedSubscriptionExpiredDescription;
        break;
      case IndeedLicenseModalType.indeedSubscriptionExpiredWithAvailable:
        buttonStatus = 'login';
        iconStatus = 'error';
        title = dictionary.indeedSubscriptionExpiredWithAvailableTitle;
        description = dictionary.indeedSubscriptionExpiredWithAvailableDescription(availableEmails);
        break;
      case IndeedLicenseModalType.noIndeedActiveSubscription:
        buttonStatus = 'info';
        iconStatus = 'error';
        title = dictionary.noIndeedActiveSubscriptionTitle;
        description = dictionary.noIndeedActiveSubscriptionDescription(loggedInEmail);
        break;
      case IndeedLicenseModalType.noIndeedActiveSubscriptionWithAvailable:
        buttonStatus = 'login';
        iconStatus = 'error';
        title = dictionary.noIndeedActiveSubscriptionWithAvailableTitle;
        description = dictionary.noIndeedActiveSubscriptionWithAvailableDescription(loggedInEmail, availableEmails);
        break;
    }

    return { buttonStatus, iconStatus, title, description } satisfies {
      buttonStatus: 'login' | 'info';
      iconStatus: 'success' | 'error';
      title: React.ReactNode;
      description: React.ReactNode;
    };
  }, [dictionary, type, availableEmails]);
};
