import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignCandidateResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { ICandidate } from 'types/candidate';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useUnlock = (params: WrappedHookParams) => {
  return useCandidateAction<UnlockHookCallbackParams, UnlockCallbackParams>(
    async ({ candidate, reason }) => {
      const { data: updatedCandidate } = await campaignsClient.createOrUpdateCampaignCandidate({
        campaignId: candidate.campaignId,
        esPersonId: candidate.es_person_id,
        status: candidate.status,
        ...(candidate.isUnlocked ? {} : { is_unlocked: true, reason }),
      });

      return { updatedCandidate };
    },
    {
      onSuccess: () => {
        return async ({ candidateOrchestrator: co, pageContext, data: { updatedCandidate } }) => {
          if (pageContext === PageContext.Campaign) {
            co.campaign.update.locally(updatedCandidate);
          } else if (pageContext === PageContext.RatedCandidates) {
            co.ratedCandidates.update.locally(updatedCandidate);
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            co.share.update.locally(updatedCandidate);
          }
        };
      },
    },
  )(params);
};

type UnlockCallbackParams = {
  updatedCandidate: CampaignCandidateResponse;
};

type UnlockHookCallbackParams = {
  reason: ICandidate.UnlockReason;
};
