import { useContext, useMemo } from 'react';
import { AuthContext } from 'shared/contexts/AuthContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';

export type UseAuthHook = () => {
  isLoggedIn: boolean;
  isStaff: boolean;
  isLoading: boolean;
  activeToken: string | null;
};

const useAuth: UseAuthHook = () => {
  const { isLoggedIn, currentUser, isFirstUserLoads, isInvalidToken } = useContext(UserContext);
  const { activeTokenType, isFirstTokenLoads, activeToken } = useContext(AuthContext);

  return useMemo(() => {
    return {
      activeToken: activeToken,
      isLoggedIn: isInvalidToken ? false : isLoggedIn,
      isStaff: Boolean(currentUser?.is_staff) || activeTokenType === 'impersonate',
      isLoading: (isFirstTokenLoads || isFirstUserLoads) && !isInvalidToken,
    };
  }, [activeTokenType, currentUser?.is_staff, isFirstTokenLoads, isLoggedIn, isFirstUserLoads, isInvalidToken]);
};

export default useAuth;
