import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { useLocation } from 'react-router-dom';
import { NotificationsContextProps, NotificationsContextProviderProps } from './types';
import { paramBind } from 'helpers/useQueryHelpers';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';
import { useUnreadLinkedInMessages } from 'shared/hooks/useBrowserExtension/useUnreadLinkedInMessages';
import { useUnreadLinkedInRecruiterMessages } from 'shared/hooks/useBrowserExtension/useUnreadLinkedinRecruiterMessages';
import { useUnreadIndeedMessages } from 'shared/hooks/useBrowserExtension/indeed/useUnreadIndeedMessages';

const defaultContext: NotificationsContextProps = {
  notifications: [],
  newNotifications: [],
  acknowledgeNotification: () => undefined,
  acknowledgeAllnotifications: () => undefined,
  count: 0,
  newNotificationsCount: 0,
};

// const REFETCH_INTERVAL = 1000 * 60 * 5; // 5 Minutes

export const NotificationsContext = createContext(defaultContext);

export const NotificationsContextProvider = ({ children }: NotificationsContextProviderProps) => {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useContext(UserContext);
  const { changesSaved } = useContext(LayoutContext);
  const { search } = useLocation();

  useUnreadLinkedInMessages();
  useUnreadLinkedInRecruiterMessages();

  useUnreadIndeedMessages();

  const pathParamNotificationId = new URLSearchParams(search).get('notification');
  const notificationToAcknowledge = pathParamNotificationId ? parseInt(pathParamNotificationId) : null;

  const acknowledgeAllnotifications = useCallback(() => {
    notificationsClient.acknowledgeAllNotification().then(() => {
      queryClient.invalidateQueries(['getNotifications']);
      changesSaved();
    });
  }, [changesSaved]);

  const acknowledgeNotification = useCallback(
    async (id: number) => {
      await notificationsClient.acknowledgeNotification(id);
      await queryClient.invalidateQueries(['getNotifications']);
      changesSaved();
    },
    [changesSaved],
  );

  useEffect(() => {
    if (notificationToAcknowledge) {
      setTimeout(() => {
        acknowledgeNotification(notificationToAcknowledge);
      }, 1000 * 30); // 30 Seconds
    }
  }, [acknowledgeNotification, notificationToAcknowledge]);

  const { data: { data: { results: notifications = [], count = 0 } = {} } = {} } = useQuery(
    ['getNotifications', {}],
    paramBind(notificationsClient.getNotifications),
    { enabled: isLoggedIn /** refetchInterval: REFETCH_INTERVAL */ },
  );

  const newNotifications = useMemo(() => notifications.filter(({ status }) => status === 'new'), [notifications]);

  const memoizedValue = useMemo(() => {
    return {
      acknowledgeNotification,
      acknowledgeAllnotifications,
      notifications,
      count,
      newNotifications,
      newNotificationsCount: newNotifications.length,
    };
  }, [acknowledgeNotification, acknowledgeAllnotifications, count, newNotifications, notifications]);

  return <NotificationsContext.Provider value={memoizedValue}>{children}</NotificationsContext.Provider>;
};
