import { useCallback, useContext, useMemo } from 'react';
import { CampaignResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { routes } from 'config';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';

const getCampaignData = async (
  legacyCampaignId: string | null,
  campaignId: string | number,
): Promise<CampaignResponse> => {
  if (legacyCampaignId) {
    const {
      data: [campaignData],
    } = await campaignsClient.getCampaigns({
      legacy_assigment_id: parseInt(legacyCampaignId),
    });
    return campaignData;
  }

  const { data: campaignData } = await campaignsClient.getCampaign(campaignId);
  return campaignData;
};

export const useContainer = () => {
  const { impersonate } = useContext(UserContext);
  const navigate = useNavigate();

  const onSuccess = useCallback(
    async (accessToken: string, legacyCampaignId: string | null, campaignId: string | number) => {
      await impersonate(accessToken);

      const campaignData = await getCampaignData(legacyCampaignId, campaignId);
      const hasCampaignData = !!campaignData?.id;

      const route = hasCampaignData ? routes.search({ campaignId: campaignData.id }) : routes.home;
      navigate(route);
    },
    [impersonate, navigate],
  );

  return useMemo(
    () => ({
      states: {},
      functions: {
        onSuccess,
      },
    }),
    [onSuccess],
  );
};
