import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';
import landingPageImage from './landingpage.png';

type LandingPageOverlayProps = { isSidebarOpen: boolean };

const styles = {
  overlayModal: css`
    background-color: transparent;
  `,
  root: ({ props: { isSidebarOpen } }) => css`
    display: flex;
    position: absolute;
    top: 0;
    width: calc(100% - 64px - ${isSidebarOpen ? 350 : 0}px);
    height: 100vh;
    margin-left: ${isSidebarOpen ? -26 : -14}px;
    align-items: center;
  `,
  background: css`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;

    background-image: url(${landingPageImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `,
} satisfies Styles<LandingPageOverlayProps>;

export const useStyles = withStyles(styles);
