import type { IndeedLicense } from 'clients/UserClient/types';

import { useMutation } from '@tanstack/react-query';
import { IndeedLicenseEmailType } from 'clients/UserClient/types';
import { userClient } from 'clients/UserClient/UserClient';
import { logger } from 'config/logger';
import { queryClient } from 'config/queryClient';
import { isAxiosError } from 'helpers/clientHelpers';
import { isObject } from 'helpers/helpers';
import { useCurrentUserQuery } from 'shared/hooks/useCurrentUserQuery';
import { QueryKey } from 'types/query';

export enum IndeedLicenseError {
  INDEED_LICENSE_IN_USE = 'INDEED_LICENSE_IN_USE',
}

export const useIndeedAssociateLicenseMutation = () => {
  const { data: currentUser } = useCurrentUserQuery();

  return useMutation(
    async ({ license_id, user_id }: { license_id: number; user_id?: number | null }) => {
      if (!currentUser) throw Error('User is logged out');
      const new_user_id = user_id === null ? null : user_id ?? currentUser.id;

      try {
        const { availableLicenses, license: licenseOldState } = await getCurrentStateLicenses(license_id);

        const { data: association } = await userClient.associateIndeedLicense({
          id: license_id,
          user_id: new_user_id,
        });

        await notifyUsers({ availableLicenses, licenseOldState, new_user_id });

        return association;
      } catch (e) {
        if (
          isAxiosError(e) &&
          isObject(e.response?.data) &&
          e.response.data.error_code === IndeedLicenseError.INDEED_LICENSE_IN_USE
        ) {
          return IndeedLicenseError.INDEED_LICENSE_IN_USE;
        }

        throw e;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.useCurrentUserQuery], { exact: false });
        queryClient.invalidateQueries([QueryKey.useIndeedModalsUpdatedLicenses], { exact: false });
        queryClient.invalidateQueries([QueryKey.useIndeedCommonModelQuery], { exact: false });
        queryClient.removeQueries([QueryKey.useTenantUserQuery], { exact: false });
        queryClient.removeQueries([QueryKey.useTenantUsersQuery], { exact: false });
      },
    },
  );
};

const getCurrentStateLicenses = async (license_id: number) => {
  const [{ data: licenses }, { data: license }] = await Promise.all([
    userClient.getIndeedLicense(),
    userClient.getIndeedLicense(license_id),
  ]);

  const availableLicenses = licenses.filter((l) => !l.user && l.status === 'ACTIVE');

  return { availableLicenses, license };
};

const notifyUsers = async ({
  availableLicenses,
  licenseOldState,
  new_user_id,
}: {
  availableLicenses: IndeedLicense[];
  licenseOldState: IndeedLicense;
  new_user_id: number | null;
}) => {
  try {
    const promises: Promise<unknown>[] = [];

    if (licenseOldState.user && (licenseOldState.user.id !== new_user_id || new_user_id === null)) {
      promises.push(
        userClient.notifyUserIndeedLicenseStatusChanged({
          user_id: licenseOldState.user.id,
          email_type:
            availableLicenses.length > 0
              ? IndeedLicenseEmailType.indeedSubscriptionWithdrawnSubscriptionAvailable
              : IndeedLicenseEmailType.indeedSubscriptionWithdrawn,
        }),
      );
    }

    if (new_user_id !== null) {
      promises.push(
        userClient.notifyUserIndeedLicenseStatusChanged({
          user_id: new_user_id,
          email_type: IndeedLicenseEmailType.indeedSubscriptionGranted,
        }),
      );
    }

    await Promise.all(promises);
  } catch (e) {
    console.error(e);
    logger.error({
      error: e,
      customMessage: 'Error when notifying users about indeed license assignment changed',
    });
  }
};
