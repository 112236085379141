import { css, extractTransition, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    display: flex;
    align-items: center;
    height: 48px;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px 8px 8px 16px;
    justify-content: space-between;
    background-color: ${theme.colors.neutral.variant[0]};

    ${extractTransition((f) => f.cubic, { property: ['border', 'background-color', 'box-shadow'], duration: 120 })}

    &:hover:not(:has(.multiFloatingContainer:hover, .multiFloatingContainer.m-active, .multiFloatingContainer:focus)) {
      background-color: ${theme.colors.neutral.variant[10]};
    }

    &[keys-navigator--parent-focused='true'],
    & > a {
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 12px 18px;
    }
  `,
  isLoading: css`
    user-select: none;
    pointer-events: none;
  `,
  selected: ({ theme }) => css`
    & {
      background-color: ${theme.colors.neutral.variant[20]};
    }

    &:has(.multiFloatingContainer:hover) {
      background-color: ${theme.colors.neutral.variant[10]};
    }
  `,
  cardInfo: () => css`
    display: flex;
    align-items: center;
    height: 36px;
    gap: 2px;

    & > div:first-of-type > div:first-of-type {
      white-space: nowrap;
    }
  `,
  textEllipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  `,
  campaignName: css`
    max-width: 100%;
    min-width: 0;
  `,
  multiFloatingRoot: ({ theme }) => css`
    display: flex;
    gap: 4px;

    &:hover {
      background-color: ${theme.colors.neutral.variant[10]};
      border-radius: 4px;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
