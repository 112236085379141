import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { allowedAtsAiCreation, routes } from 'config';
import { queryClient } from 'config/queryClient';
import { isAxiosError } from 'helpers/clientHelpers';
import { useAtsVacancyQuery } from 'hooks/autocomplete/useAtsVacancyQuery';
import { useGTM } from 'hooks/gtm';
import { useSearchRatedCandidateQuery } from 'hooks/search/useSearchRatedCandidate';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { CandidateStatus } from 'services/candidates';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { QueryKey } from 'types/query';
import { useContextSelector } from 'use-context-selector';
import { campaignFirstFetchManager } from 'utils/campaigns/campaignFirstFetchManager';
import { ATSIntegrationContext } from 'views/LoggedIn/components/ATSIntegrations/contexts/ATSIntegrationContext';

export const MIN_LENGTH = 1;
export const MAX_LENGTH = 4000;

export const useContainer = () => {
  const setSelectedCampaignId = useContextSelector(CampaignManagerContext, (state) => state.setSelectedCampaignId);
  const activeATS = useActiveAtsMemoized();
  const isAtsEnabled = useMemo(() => allowedAtsAiCreation.includes(activeATS?.toLowerCase() || ''), [activeATS]);
  const navigate = useNavigate();
  const gtm = useGTM();
  const { createSnackbar } = useContext(SnackbarContext);
  const { dictionary } = useContext(LocalizationContext);
  const resultsDivRef = useRef<HTMLDivElement>(null);
  const [isCreatingSearch, setIsCreatingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchDebouncedValue, setSearchDebouncedValue] = useState('');
  const [isDropdownOpenDebounced, setIsDropdownOpenDebounced] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useDebounce(() => setSearchDebouncedValue(searchValue), 300, [searchValue]);

  const { data: atsVacancies, isFetching: atsVacanciesIsFetching } = useAtsVacancyQuery({
    enabled: isAtsEnabled,
    search: searchDebouncedValue,
  });
  const { data: ratedCandidates, isFetching: ratedCandidatesIsFetching } = useSearchRatedCandidateQuery({
    search: searchDebouncedValue,
  });
  const campaigns = useContextSelector(CampaignManagerContext, (state) => state.campaigns);
  const filters = useContextSelector(CampaignManagerContext, (state) => state.filters);

  useEffect(() => {
    filters.setName(searchDebouncedValue);
  }, [searchDebouncedValue]);

  useDebounce(() => setIsDropdownOpenDebounced(isDropdownOpen), isDropdownOpenDebounced ? 150 : 0, [isDropdownOpen]);

  const withCreatingLoader = useCallback(
    (cb: () => Promise<void>) => {
      setIsCreatingSearch(true);
      cb()
        .catch((e: unknown) => {
          const message =
            isAxiosError(e) && e.response?.status === 400
              ? dictionary.didntFindJobTitleLocation
              : dictionary.somethingWentWrong;

          createSnackbar(message, { variant: 'danger' });
          gtm.campaignCreationFailed();
        })
        .finally(() => {
          setIsCreatingSearch(false);
        });
    },
    [dictionary.somethingWentWrong],
  );

  const createTextSearch = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement> | undefined, search: string) => {
      if (!e?.shiftKey) {
        e?.preventDefault();
        withCreatingLoader(async () => {
          const {
            data: { id },
          } = await campaignsClient.createTextSearch({ job_description: search });

          campaignFirstFetchManager.set(id);
          setSearchValue('');
          await queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaigns]);
          navigate(routes.search({ campaignId: id, justCreatedNewCampaign: true }));
        });
        e?.currentTarget?.blur();
      }
    },
    [withCreatingLoader],
  );

  const createAtsSearch = useCallback(
    (ats_id: string) => {
      withCreatingLoader(async () => {
        const {
          data: { id },
        } = await campaignsClient.createAtsSearch({ ats_id });

        gtm.newAtsCampaignCreated({ campaignId: id });
        campaignFirstFetchManager.set(id);
        setSearchValue('');
        await queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaigns]);
        navigate(routes.search({ campaignId: id, justCreatedNewCampaign: true }));
      });
    },
    [withCreatingLoader],
  );

  const handleSearchChange = useCallback((text: string) => {
    setSearchValue(text);
  }, []);

  const handleAtsVacancySelect = useCallback(
    (ats_id: string) => {
      return createAtsSearch(ats_id);
    },
    [createAtsSearch],
  );

  const handleCandidateSelect = useCallback(
    ({ campaignId, candidateId, userId }: { campaignId: number; candidateId: string; userId?: number }) => {
      gtm.openedCandidateFullProfile();

      navigate(routes.candidatePage({ campaignId: campaignId, candidateId: candidateId, userId }));
    },
    [],
  );

  const handleOpenDropdown = useCallback(() => {
    gtm.campaignOverviewSearch();
    setIsDropdownOpen(true);
  }, []);

  const handleSearchSelect = useCallback((id: number, initialTab?: CandidateStatus) => {
    gtm.recentSearchCampaignOpened({ campaignId: id });
    setSelectedCampaignId(id);
    navigate(routes.search({ campaignId: id, tab: initialTab }));
  }, []);

  const onViewAllCandidates = () => navigate(routes.ratedCandidates);

  return useMemo(() => {
    const isTextToSearch = searchValue.length >= MIN_LENGTH;

    return {
      values: {
        resultsDivRef,
        search: {
          isTextToSearch,
          value: searchValue,
          length: searchValue.length,
          handleSelect: (search_id: number) => handleSearchSelect(search_id),
          handleChange: (text: string) => !isCreatingSearch && handleSearchChange(text),
          createTextSearch: (e?: React.KeyboardEvent<HTMLTextAreaElement>) =>
            !isCreatingSearch && createTextSearch(e, searchValue),
          isCreating: isCreatingSearch,
        },
        atsVacancies: {
          value: atsVacancies?.results || [],
          isLoading: atsVacanciesIsFetching,
          handleSelect: (ats_id: string) => !isCreatingSearch && handleAtsVacancySelect(ats_id),
        },
        ratedCandidates: {
          value: ratedCandidates?.results || [],
          isLoading: ratedCandidatesIsFetching,
          onViewAllCandidates,
          handleSelect: ({
            campaignId,
            candidateId,
            userId,
          }: {
            campaignId: number;
            candidateId: string;
            userId?: number;
          }) => !isCreatingSearch && handleCandidateSelect({ campaignId, candidateId, userId }),
        },
        campaignsOverview: {
          value: campaigns,
          isLoading: campaigns?.isLoading,
          handleSelect: (campaignId: number, initialTab?: CandidateStatus) =>
            !isCreatingSearch && handleSearchSelect(campaignId, initialTab),
        },
        dropdown: {
          isOpen: isDropdownOpenDebounced,
          handleOpen: handleOpenDropdown,
          handleClose: () => setIsDropdownOpen(false),
        },
      },
    };
  }, [
    isDropdownOpenDebounced,
    campaigns,
    atsVacancies?.results,
    ratedCandidates?.results,
    searchValue,
    atsVacanciesIsFetching,
    isCreatingSearch,
    isAtsEnabled,
    createTextSearch,
    handleAtsVacancySelect,
  ]);
};

const useActiveAtsMemoized = () => {
  const { activeATS } = useContext(ATSIntegrationContext);
  const atsName = activeATS?.ats_name;

  return useMemo(() => atsName, [atsName]);
};
