import { Tenant } from 'clients/Admin/TenantClient/types';
import { MouseEventHandler, useContext, useMemo, useState } from 'react';
import { Button } from 'components/ui/molecules';
import { TableBodyActionItemType, TableBodyActionModals, TableBodyActionsProps, TableBodyProps } from './types';
import { useStyles } from './TableBody.styles';
import { Database, Edit2, Eye, PlayCircle, Slash, Sliders, UsersV2 } from 'shared/icons';
import { MenuChangeHandler } from 'shared/components/Menu/types';
import { CreateOrEditTenantModal, EditTenantUsersModal, FeatureTogglesModal } from './components';
import EditCvSourceModal from './components/EditCvSourcesModal';
import ImpersonateModal from './components/ImpersonateModal';
import { tenantClient, tenantClientCacheKeys } from 'clients/Admin';
import { extractErrorMessageFromAxiosResponse } from 'helpers/clientHelpers';
import { BaseError } from 'clients/types';
import { useQueryClient } from '@tanstack/react-query';
import { useGTM } from 'hooks/gtm';
import DialogContext from 'shared/contexts/DialogContext';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { CloseModalHandler } from 'shared/components/Modal';
import Table, { TableHeaderType } from 'shared/components/Table';
import Menu from 'shared/components/Menu';
import WerknlCredentialsModal from './components/WerknlCredentialsModal';
import { Briefcase_01, Lock_01, RobinIconMono } from '@recruitrobin/robin-theme/web-icons';
import EnableRobinResumeModal from './components/EnableRobinResumeModal';

/**
 * 100vh = total height of screen
 * 68px = header height
 * 64px = body padding (top + bottom)
 * 59.9px = filters height
 * 40px = margin between filters and table header
 * 44px = table header height
 * 16px = margin between table and pagination controls
 * 38px = pagination controls heigh
 */
const TABLE_BODY_HEIGHT = 'calc(100vh - 68px - 64px - 59.9px - 40px - 44px - 16px - 38px)';

const TableBodyActions = ({ tenant, retrieveTableData }: TableBodyActionsProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const queryClient = useQueryClient();
  const gtm = useGTM();
  const actionItems: TableBodyActionItemType[] = useMemo(
    () => [
      { icon: <Edit2 size={16} />, label: dictionary.editCompany, value: 'edit-tenant' },
      { icon: <UsersV2 size={16} />, label: dictionary.editUsers, value: 'edit-users' },
      { icon: <Eye size={16} />, label: dictionary.impersonateUser, value: 'impersonate' },
      { icon: <Database size={16} />, label: dictionary.creditLimit, value: 'credit-limit' },
      { icon: <Briefcase_01 size={16} />, label: dictionary.jobBoards, value: 'edit-cv-sources' },
      { icon: <Sliders size={16} />, label: dictionary.featureToggles, value: 'feature-toggles' },
      {
        icon: <RobinIconMono size={16} />,
        label: dictionary.robinResume,
        value: 'robin-resume',
      },
      { icon: <Lock_01 size={16} />, label: 'Werknl Credentials', value: 'werknl-eherkenning-credentials' },
      {
        variant: 'warning',
        value: 'change-active-state',
        ...(tenant.is_active
          ? { icon: <Slash size={16} />, label: dictionary.deactivate }
          : { icon: <PlayCircle size={16} />, label: dictionary.activate }),
      },
    ],
    [tenant.is_active, dictionary],
  );

  const classes = useStyles();
  const { setSnackbarState } = useContext(LayoutContext);
  const { createDialog } = useContext(DialogContext);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [openedModal, setOpenedModal] = useState<TableBodyActionModals | null>(null);

  const handleOpenMenu: MouseEventHandler = (e) => {
    setButtonRef(e.currentTarget as HTMLButtonElement);
  };

  const handleCloseMenu = () => {
    setButtonRef(null);
  };

  const handleOpenModal: MenuChangeHandler<TableBodyActionItemType> = ({ value }) => {
    handleCloseMenu();

    if (value === 'change-active-state') {
      return createDialog().then(() => {
        tenantClient
          .patch({
            id: tenant.id,
            is_active: !tenant.is_active,
          })
          .then(() => {
            !tenant.is_active ? gtm.activateTenant() : gtm.deactivateTenant();
            setSnackbarState({
              open: true,
              message: dictionary.updateUsersSuccessfully,
              severity: 'success',
              autoHideDuration: 3000,
            });
            queryClient.invalidateQueries([tenantClientCacheKeys.index]).then(() => retrieveTableData());
          })
          .catch((e) =>
            setSnackbarState({
              open: true,
              message: extractErrorMessageFromAxiosResponse(e as BaseError),
              severity: 'error',
              autoHideDuration: 3000,
            }),
          );
      });
    }

    setOpenedModal(value);
  };

  const handleCloseModal: CloseModalHandler = (reason) => {
    if (reason === 'success') {
      retrieveTableData();
    }

    setOpenedModal(null);
  };

  return (
    <div css={classes.actions}>
      <Button onClick={handleOpenMenu} label={dictionary.actions} />
      <Menu anchorEl={buttonRef} menuItems={actionItems} onChange={handleOpenModal} onClose={handleCloseMenu} />
      {openedModal && (
        <>
          <CreateOrEditTenantModal
            tenant={tenant}
            onClose={handleCloseModal}
            focusCreditLimit={openedModal === 'credit-limit'}
            visible={openedModal === 'edit-tenant' || openedModal === 'credit-limit'}
          />
          <EditTenantUsersModal onClose={handleCloseModal} visible={openedModal === 'edit-users'} tenant={tenant} />
          <ImpersonateModal onClose={handleCloseModal} visible={openedModal === 'impersonate'} tenantId={tenant.id} />
          <WerknlCredentialsModal
            onClose={handleCloseModal}
            visible={openedModal === 'werknl-eherkenning-credentials'}
            tenantId={tenant.id}
          />
          <EnableRobinResumeModal
            onClose={handleCloseModal}
            visible={openedModal === 'robin-resume'}
            tenantId={tenant.id}
          />
          <EditCvSourceModal
            onClose={handleCloseModal}
            visible={openedModal === 'edit-cv-sources'}
            tenantId={tenant.id}
          />
          <FeatureTogglesModal
            onClose={handleCloseModal}
            visible={openedModal === 'feature-toggles'}
            tenantId={tenant.id}
          />
        </>
      )}
    </div>
  );
};

const TableBody = ({ tenants, paginationConfigs, retrieveTableData, onSort, loading = false }: TableBodyProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();
  const HEADERS: Array<TableHeaderType<keyof Tenant>> = [
    { label: dictionary.id, identifier: 'id' },
    { label: dictionary.companyName, identifier: 'name' },
    { label: dictionary.companyCode, identifier: 'code' },
    { label: dictionary.edition, identifier: 'subscription_edition' },
    { label: dictionary.active, identifier: 'is_active' },
    { label: dictionary.credits, identifier: 'credit_limit' },
    { label: dictionary.created, identifier: 'created_at' },
    { label: dictionary.website, identifier: 'privacy_policy_link' },
    { label: dictionary.paymentMethod, identifier: 'payment_method' },
  ];

  return (
    <div css={classes.root}>
      <Table
        data={tenants}
        headerConfig={{ headers: HEADERS, onSort }}
        paginationConfig={paginationConfigs}
        extraColumns={[(row) => <TableBodyActions tenant={row} retrieveTableData={retrieveTableData} />]}
        mapRowProp={{
          created_at: (value) => `${new Date(value as string).toLocaleDateString()}`,
          is_active: (value) => ((value as boolean) ? dictionary.yes : dictionary.no),
          payment_method: (value) => (value === 'PAY_PER_MATCH' ? 'Pay per match' : 'Pay per credit'),
          trigger_to_send_candidate_to_ats: (value) => (value === 'AFTER_MATCH' ? 'After match' : 'After approval'),
        }}
        loading={loading}
        bodyHeight={TABLE_BODY_HEIGHT}
      />
    </div>
  );
};

export default TableBody;
