import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    max-width: 600px;
    width: 100%;
    padding: 0 21px 0;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
