import { useMemo } from 'react';
import { GlobalModalsContext } from './GlobalModalsContext';
import { useIndeedModals } from './useIndeedModals';

export const GlobalModalsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [indeedModalHandlers, indeedModal] = useIndeedModals();

  const value = useMemo(() => ({ indeedModalHandlers }), [indeedModalHandlers]);

  return (
    <GlobalModalsContext.Provider value={value}>
      {children}
      {indeedModal}
    </GlobalModalsContext.Provider>
  );
};
