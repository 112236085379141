import type { CampaignCandidateResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import type { GTMMethods } from 'hooks/gtm/useGTM';
import type { JobTitle } from '../SearchContext/FiltersContext/types';

import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { FilterOrder } from 'clients/types';
import { logger } from 'config/logger';
import { queryClient } from 'config/queryClient';
import { isObject } from 'helpers/helpers';
import { useGTM } from 'hooks/gtm';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { default as Candidate } from 'model/Candidate';
import { useMemo } from 'react';
import { StopExecution } from 'shared/exceptions';
import { QueryKey } from 'types/query';
import { useContextSelector } from 'use-context-selector';
import { campaignFirstFetchManager } from 'utils/campaigns/campaignFirstFetchManager';
import generateLabelsCacheKey from '../CandidateContext/searchv3/generateLabelsCacheKey';
import { CampaignSearchResults, SearchResultCandidateResponse } from '../CandidateContext/types';
import { FiltersContext } from '../SearchContext';
import { CampaignTab, SelectedCampaignFilterState } from './SelectedCampaignContext.types';
import { setStats } from './SelectedCampaignContext.useStats';

export const PAGINATION_LENGTH = 20;

export const useCandidates = ({
  campaignId,
  page,
  tab,
  filters,
}: {
  campaignId: number | undefined;
  page: number;
  tab: CampaignTab;
  filters: SelectedCampaignFilterState;
}): { candidates: Candidate[]; isLoading: boolean; total: number } => {
  const isLoadingSaveFilters = useContextSelector(FiltersContext, (state) => state.isLoadingSaveFilters);
  const { order, labels } = filters;
  const pageContext = usePageContext();
  const labelsKey = generateLabelsCacheKey(filters.labels);
  const gtm = useGTM();

  const { data, isFetching } = useQuery(
    [QueryKey.selectedCampaignContextUseCandidates, { id: campaignId, tab, page, order, labelsKey }],

    async () => {
      const [offset, limit] = [PAGINATION_LENGTH * (page - 1), PAGINATION_LENGTH];

      if (!campaignId) return;

      if (tab === CampaignTab.New) {
        const { data, total, jobTitles } = await fetchNewCandidates({ id: campaignId, limit, offset });

        proccessFirstCampaignFetch({ campaignId, jobTitles, total, gtm });

        return { data, total };
      }

      return fetchRatedCandidates({ id: campaignId, status: tab, limit, offset, order, labels });
    },
    { enabled: typeof campaignId === 'number' && pageContext === PageContext.Campaign, retry: 1 },
  );

  const candidates = useMemo<Candidate[]>(() => data?.data || [], [data]);

  return useMemo(
    () => ({ candidates, isLoading: isFetching || isLoadingSaveFilters, total: data?.total || 0 }),
    [candidates, isFetching, isLoadingSaveFilters, data?.total],
  );
};

const fetchNewCandidates = async ({
  id,
  offset,
  limit,
}: {
  id: number;
  offset: number;
  limit: number;
  labels?: string[];
}): Promise<{ total: number; data: Candidate[]; jobTitles: JobTitle[] }> => {
  const { data } = await campaignsClient.getSearchResults({ campaignId: id, limit, offset });

  /// TODO: Is there a better way to manage colateral effects of updating a campaign?
  setStats(data.id, (stats) => ({ ...stats, [CampaignTab.New]: data.total_candidates_count }));

  const candidates = extractCandidatesFromSearchResults(data);

  return {
    data: candidates.map<Candidate>((candidate) => new Candidate(id, parseToCandidateResponse(candidate))),
    total: data.total_candidates_count,
    jobTitles: data.current_filter.filters.job_titles as JobTitle[],
  };
};

const fetchRatedCandidates = async ({
  id,
  status,
  limit,
  offset,
  order,
  labels,
}: {
  id: number;
  status: CampaignTab;
  offset: number;
  limit: number;
  order: FilterOrder;
  labels: string[];
}): Promise<{ total: number; data: Candidate[] }> => {
  const { data } = await campaignsClient.getCampaignRatedCandidate({
    id,
    limit,
    offset,
    status,
    sort: { by: 'updated_at', order },
    labels,
  });

  return { data: data.results.map((candidate) => new Candidate(id, candidate)), total: data.count };
};

const extractCandidatesFromSearchResults = (data: any): SearchResultCandidateResponse[] => {
  const candidates = (data as CampaignSearchResults).search_results.hits.hits || [];

  return candidates;
};

const parseToCandidateResponse = (candidate: unknown): CampaignCandidateResponse => {
  if (!isObject(candidate)) throw Error('Invalid candidate format');

  return {
    reasons: candidate.reasons ?? null,
    is_unlocked: candidate.is_unlocked ?? false,
    candidate: candidate,
  } as any;
};

export const setCandidates = (
  {
    id,
    tab,
    page,
    order,
    labelsKey,
  }: { id: number | undefined; tab: CampaignTab; page: number; order: FilterOrder; labelsKey: string },
  callback: (data: Candidate[]) => Candidate[],
) => {
  queryClient.setQueryData<{ data: Candidate[]; total: number }>(
    [QueryKey.selectedCampaignContextUseCandidates, { id, tab, page, order, labelsKey }],
    (data) => {
      if (!data) {
        console.warn('[DEVLOG] [Candidate[] is undefined]', { id, tab, page, order, labelsKey });

        throw new StopExecution('Candidate[] is undefined');
      }

      return { data: callback(data.data), total: data.total };
    },
  );
};

export const setRatedCandidates = (
  {
    tab,
    page,
    order,
    labelsKey,
    showTeamView,
  }: { tab: CampaignTab; page: number; order: FilterOrder; labelsKey: string; showTeamView: boolean },
  callback: (data: Candidate[]) => Candidate[],
) => {
  queryClient.setQueryData<{ results: Candidate[]; count: number }>(
    [QueryKey.useRatedCandidatesQuery, { tab, page, order, labelsKey, showTeamView }],
    (data) => {
      if (!data) {
        console.warn('[DEVLOG] [Candidate[] is undefined]', { tab, page, order, labelsKey, showTeamView });

        throw new StopExecution('Candidate[] is undefined');
      }

      return { results: callback(data.results), count: data.count };
    },
  );
};

const proccessFirstCampaignFetch = ({
  campaignId,
  jobTitles,
  gtm,
  total,
}: {
  campaignId: number;
  jobTitles: JobTitle[];
  gtm: GTMMethods;
  total: number;
}) => {
  const isFirstSearchBatchesRequestForThisSearch = campaignFirstFetchManager.verify(campaignId);

  if (isFirstSearchBatchesRequestForThisSearch) {
    campaignFirstFetchManager.remove(campaignId);

    const aiJobTitles = jobTitles.filter((jt) => jt.isSynonym).length;
    const manualJobTitles = jobTitles.length - aiJobTitles;

    try {
      gtm.campaignInitialSearchResults({ campaignId, total, aiJobTitles, manualJobTitles });
    } catch (e) {
      logger.error({
        error: e,
        customMessage: 'Error when sending campaign first fetch info to GTM',
      });
    }
  }
};
