import { Source } from 'clients/CampaignsClient/CandidateResponse';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';
import { shouldRemoveCandidateAfterRefresh } from 'config';
import { delay } from 'helpers/helpers';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CandidateNotFoundException, NoCreditsAvailableForMonsterboard } from 'shared/exceptions';
import { CAMPAIGN_STATS } from 'types/campaign';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';
import { useRemoveCandidateMatch } from './useRemoveCandidateMatch';

export const useRefresh = (params: WrappedHookParams) => {
  const removeCandidateMatch = useRemoveCandidateMatch({ candidate: params.candidate, setIsLoading: () => undefined });

  return useCandidateAction<RefreshHookCallbackParams, SaveLabelsSucessCallbackParams>(
    async ({ candidate, requestDetail }) => {
      const { data: { candidate: source } = {} } = await crawlerClient.refreshProfile({
        es_person_id: candidate.es_person_id_without_platform,
        platform: candidate.activeJobBoard,
        force: true,
        requestDetail,
      });

      if (!source) {
        throw new Error('Source cannot be empty!');
      }

      return { source };
    },
    {
      onSuccess: () => {
        return async ({ candidateOrchestrator: co, pageContext, data: { source } }) => {
          if (pageContext === PageContext.Campaign) {
            co.campaign.update.source(source);
          } else if (pageContext === PageContext.RatedCandidates) {
            co.ratedCandidates.update.source(source);
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            co.share.update.source(source);
          }
        };
      },
      onError: ({ fcParams: { candidate } }) => {
        return async ({ candidateOrchestrator: co, pageContext, data }) => {
          if (data instanceof CandidateNotFoundException) {
            if (
              pageContext === PageContext.Campaign &&
              co.campaign.get.status() === CAMPAIGN_STATS.NEW &&
              shouldRemoveCandidateAfterRefresh
            ) {
              co.campaign.markAs.noLongerAvailable();
              await delay(3000);
              if (candidate.mainJobboard !== candidate.activeJobBoard) {
                await removeCandidateMatch({ campaignId: candidate.campaignId });
              } else {
                co.campaign.decreaseStatOf.currentJobBoard();
                co.campaign.remove.fromCurrentList();
                co.campaign.decreaseStatOf.currentList();
              }
            }
          }
          if (data instanceof NoCreditsAvailableForMonsterboard) {
            throw data;
          }
        };
      },
    },
  )(params);
};

type SaveLabelsSucessCallbackParams = {
  source: Source;
};

type RefreshHookCallbackParams = {
  requestDetail?: boolean;
};
