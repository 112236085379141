export const CANDIDATE_STATUS = {
  NEW: 'NEW',
  SHORTLISTED: 'SHORTLISTED',
  CONTACTED: 'CONTACTED',
  MATCHED: 'MATCHED',
  HIDDEN: 'HIDDEN',
  INVISIBLE: 'INVISIBLE',
} as const;

export enum CandidateHistoryAction {
  /** activity log */
  Unlocked = 'CAMPAIGN_CANDIDATE_UNLOCKED',
  Matched = 'CAMPAIGN_CANDIDATE_MATCHED',
  Hidden = 'CAMPAIGN_CANDIDATE_HIDDEN',
  Shortlisted = 'CAMPAIGN_CANDIDATE_SHORTLISTED',
  Invisible = 'CAMPAIGN_CANDIDATE_INVISIBLE',
  Contacted = 'CAMPAIGN_CANDIDATE_CONTACTED',
  SentToAts = 'CAMPAIGN_CANDIDATE_SENT_TO_ATS',
  LabelChanged = 'CAMPAIGN_CANDIDATE_LABEL_CHANGED',

  /** history */
  Note = 'CAMPAIGN_CANDIDATE_NOTE',
  EmailSent = 'CAMPAIGN_CANDIDATE_EMAIL_SENT',
  EmailReceived = 'CAMPAIGN_CANDIDATE_EMAIL_RECEIVED',
  InMailSent = 'CAMPAIGN_CANDIDATE_LINKEDIN_INMAIL_SENT',
  InMailReceived = 'CAMPAIGN_CANDIDATE_LINKEDIN_INMAIL_RECEIVED',
  LinkedinSent = 'CAMPAIGN_CANDIDATE_LINKEDIN_MESSAGES_SENT',
  LinkedinReceived = 'CAMPAIGN_CANDIDATE_LINKEDIN_MESSAGES_RECEIVED',

  MessageReceived = 'CAMPAIGN_CANDIDATE_MESSAGE_RECEIVED',

  /** ats actions */
  AtsNoteCreated = 'ATS_CANDIDATE_NOTE_CREATED',
  AtsStatusChanged = 'ATS_CANDIDATE_STATUS_CHANGED',
  AtsCandidateContact = 'ATS_CANDIDATE_CONTACT',
}

export type CandidateStatus = keyof typeof CANDIDATE_STATUS;

export const CANDIDATE_ACTION = {
  SHORTLIST: 'SHORTLIST',
  CONTACT: 'CONTACT',
  HIDE: 'HIDE',
  MATCH: 'MATCH',
  'SHOW-MORE': 'SHOW-MORE',
  ['SHARE-PROFILE']: 'SHARE-PROFILE',
  DOWNLOAD: 'DOWNLOAD',
} as const;

export const CANDIDATE_SECONDARY_ACTION = {
  MATCH: 'MATCH',
  'SHORTLIST-TO-ANOTHER-SEARCH': 'SHORTLIST-TO-ANOTHER-SEARCH',
  'RETRY-TO-SEND-TO-ATS': 'RETRY-TO-SEND-TO-ATS',
  ['SHARE-PROFILE']: 'SHARE-PROFILE',
} as const;

export type CandidateAction = keyof typeof CANDIDATE_ACTION;
export type CandidateSecondaryAction = keyof typeof CANDIDATE_SECONDARY_ACTION;
