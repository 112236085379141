import { Button, Checkbox, Loader, Typography } from '@recruitrobin/robin-theme/web-components';
import { useStyles } from './EnableRobinResumeModal.styles';
import { atsIntegrationsClient } from 'clients/ATSIntegrationsClient/ATSIntegrationsClient';
import { useContext } from 'react';
import { AtsStatus } from 'clients/ATSIntegrationsClient/types';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'types/query';
import { logger } from 'config/logger';
import { isAxiosError } from 'helpers/clientHelpers';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { Check } from '@recruitrobin/robin-theme/web-icons';
import { queryClient } from 'config/queryClient';
import { AtsStatusesListProp } from './types';

export const AtsStatusesList = ({
  ats,
  tenantId,
  setIsLoadingStatuses,
  isLoadingStatuses,
  updateFeatureToggle,
  setAtsStatus,
}: AtsStatusesListProp) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const { createSnackbar } = useContext(SnackbarContext);

  const fetchAtsStatuses = async () => {
    setIsLoadingStatuses(true);
    const response = await atsIntegrationsClient.getStatuses(tenantId, ats.name);
    setIsLoadingStatuses(false);

    return response.data;
  };

  function handleToggleStatus(statusId: number) {
    queryClient.setQueryData<AtsStatus[]>([QueryKey.atsStatusesQuery, tenantId, ats.name], (oldStatuses) => {
      return oldStatuses?.map((status) => {
        if (status.id === statusId) {
          return {
            ...status,
            is_rejected: !status.is_rejected,
          };
        }

        return status;
      });
    });
    setAtsStatus({
      ...ats,
      enabled: false,
    });
  }

  async function pullAtsStatuses() {
    await atsIntegrationsClient.pullStatuses(tenantId, ats.name);
  }

  const { isFetching: isPullingStatuses } = useQuery(['pull-statuses', tenantId, ats.name], pullAtsStatuses, {
    retry: 0,
  });

  const { data: statuses } = useQuery([QueryKey.atsStatusesQuery, tenantId, ats.name], fetchAtsStatuses, {
    enabled: !isPullingStatuses && !!ats,
  });

  async function handleSaveStatuses() {
    try {
      await atsIntegrationsClient.saveStatuses(tenantId, statuses!);
      setAtsStatus({
        ...ats,
        enabled: true,
      });
      await updateFeatureToggle(ats);

      createSnackbar(dictionary.statusSavedWithSuccess, { variant: 'success' });
    } catch (e) {
      logger.error(e);
      createSnackbar(isAxiosError(e) ? e.message : dictionary.somethingWentWrong, { variant: 'danger' });
    }
  }

  return (
    <>
      {isLoadingStatuses ? (
        <div css={classes.loading}>
          <Loader size={64} />
          <Typography variant={(v) => v.body.short} color={(c) => c.primary.fuchsia[50]}>
            {dictionary.syncingYourAtsStatuses}
          </Typography>
        </div>
      ) : (
        <>
          <div css={classes.statusesList}>
            {statuses &&
              statuses.map((status) => {
                return (
                  <Checkbox
                    key={status.id}
                    checked={status.is_rejected}
                    label={status.ats_status_name}
                    onClick={() => handleToggleStatus(status.id)}
                  />
                );
              })}
          </div>

          {ats.enabled ? (
            <div css={classes.footerConfirmed}>
              <div css={classes.statusConfirmed}>
                <Check size={18} color={(c) => c.neutral.variant[70]} />
                <Typography variant={(v) => v.supporting.label} color={(c) => c.neutral.variant[70]}>
                  {dictionary.statusConfirmed}
                </Typography>
              </div>
            </div>
          ) : (
            <div css={classes.footer}>
              <Typography variant={(v) => v.body.short}>{dictionary.statusNeedToBeConfirmedForThisTenant}</Typography>
              <Button
                startIcon={Check}
                label={dictionary.confirmStatus}
                onClick={handleSaveStatuses}
                type="highlight"
                style="tonal"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
