import { useQuery } from '@tanstack/react-query';
import { searchClient } from 'clients/SearchClient/SearchClient';
import { useLocalStorage } from 'hooks/shared';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { Candidate } from 'model';
import { useMemo } from 'react';
import generateLabelsCacheKey from 'shared/contexts/CandidateContext/searchv3/generateLabelsCacheKey';
import {
  CampaignTab,
  SelectedCampaignFilterState,
} from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';

import { QueryKey } from 'types/query';

export const CANDIDATES_PER_PAGE = 15;

export const TEAM_VIEW_RATED_CANDIDATES = 'teamViewRatedCandidates';

export const useRatedCandidatesQuery = ({
  page,
  tab,
  filters,
}: {
  page: number;
  tab: CampaignTab;
  filters: SelectedCampaignFilterState;
}) => {
  const { order, labels } = filters;
  const labelsKey = generateLabelsCacheKey(labels);
  const { value: showTeamView } = useLocalStorage(TEAM_VIEW_RATED_CANDIDATES, true);
  const pageContext = usePageContext();

  const { data, isFetching, isLoading } = useQuery(
    [QueryKey.useRatedCandidatesQuery, { tab, page, order, labelsKey, showTeamView }],
    async () => {
      if (pageContext !== PageContext.RatedCandidates) return { results: [], isLoading: false, count: 0 };

      const response = await searchClient.getSearchRatedCandidates({
        labelsFilter: labels,
        limit: CANDIDATES_PER_PAGE,
        offset: CANDIDATES_PER_PAGE * (page - 1),
        status: tab,
        sort: {
          by: 'updated_at',
          order: order,
        },
        team: showTeamView,
      });

      return {
        ...response.data,
        results: response.data.results.map((candidate) => new Candidate(candidate.campaign.id, candidate)),
      };
    },
    {
      keepPreviousData: true,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: pageContext === PageContext.RatedCandidates,
      retry: 1,
    },
  );

  const candidates = useMemo<Candidate[]>(() => data?.results || [], [data]);

  return useMemo(
    () => ({ candidates, isLoading: isFetching || isLoading, total: data?.count || 0 }),
    [candidates, isFetching, data?.count, isLoading],
  );
};
