import { useLocation, matchPath } from 'react-router-dom';
import { routes } from 'config/routes';
import { SidebarNavPages } from './SidebarNav.types';

export const useSelectedRoute = (): SidebarNavPages | null => {
  const location = useLocation();

  const routeMatches = [
    { name: SidebarNavPages.Campaign, path: routes.search({ campaignId: ':id?' }) },
    { name: SidebarNavPages.Users, path: routes.users },
    { name: SidebarNavPages.Teams, path: routes.teams },
    { name: SidebarNavPages.Dashboard, path: routes.dashboard },
    { name: SidebarNavPages.EmailSettings, path: routes.emailSettings },
    { name: SidebarNavPages.AtsConnections, path: routes.atsConnections },
    { name: SidebarNavPages.BrowserExtension, path: routes.browserExtension },
    { name: SidebarNavPages.Admin, path: routes.admin.dashboard },
    { name: SidebarNavPages.RatedCandidates, path: routes.ratedCandidates },
  ];

  const activeRoute = routeMatches.find((route) => matchPath(route.path, location.pathname));

  return activeRoute?.name ?? null;
};
