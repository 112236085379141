import { FormProvider, useForm } from 'react-hook-form';

import { routes } from 'config';

import { Button } from 'components/ui/molecules';
import { SmallLanguageSwitch } from 'shared/components/localization/SmallLanguageSwitch/SmallLanguageSwitch';

import { TextFieldWithUseForm } from 'shared/components/TextField';
import { Column, Expand } from 'components/ui/atoms';
import { RobinLogo } from '@recruitrobin/robin-theme/web-icons';
import { useStyles } from './MultiFactorAuth.styles';
import { useContext, useEffect, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { MultiFactorFormFields } from '../LogIn/LogIn.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { RequireAuthNavigateState } from 'views/Routes/components/RequireAuth/types';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { BaseCallbackError } from 'clients/UserClient/types';
import { AuthContext } from 'shared/contexts/AuthContext';
import { ClipLoader } from 'react-spinners';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { ConfigContext } from 'shared/contexts/ConfigContext/ConfigContext';
import { useContextSelector } from 'use-context-selector';
import { useAuth } from 'shared/hooks';

const CounterMfaValidation = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(600);
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (secondsRemaining > 0) {
      const intervalId = setInterval(() => {
        setSecondsRemaining((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate(routes.login);
    }
  }, [secondsRemaining]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <div css={classes.description}>
      {dictionary.enterMultiFactorAuthCode}

      <p css={classes.timer}>{formatTime(secondsRemaining)}</p>
    </div>
  );
};

export const MultiFactorAuth = () => {
  const auth = useAuth();
  const { createSnackbar } = useContext(SnackbarContext);
  const { dictionary } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const { validateMfaCode } = useContext(UserContext);
  const location = useLocation();
  const fromUrl = (location.state as RequireAuthNavigateState)?.from?.pathname;
  const classes = useStyles();
  const { userEmail } = useContext(AuthContext);
  const isIframe = useContextSelector(ConfigContext, (state) => state.isIframe);

  const methods = useForm<MultiFactorFormFields>({
    defaultValues: {
      mfa_code: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, setError } = methods;

  useEffect(() => {
    if (userEmail?.current === '') {
      console.error('User Email is unset');
      createSnackbar(dictionary.credentialsExpired, { variant: 'danger' });
      navigate(routes.login);
    }
  }, []);

  useEffect(() => {
    if (auth.activeToken) {
      if (isIframe) {
        navigate(routes.iframeCampaignsList);
        return;
      }

      navigate(routes.home);
    }
  }, [auth.activeToken, isIframe]);

  const onSubmit = async ({ ...data }: MultiFactorFormFields) => {
    if (!validateMfaCode) return;
    if (!userEmail) return;
    setIsLoading(true);

    try {
      const mfaParams = {
        ...data,
        email: userEmail?.current,
      };
      const { status } = await validateMfaCode(mfaParams);

      if (status === 201) {
        setIsLoading(false);
        const isLoginPage = fromUrl === routes.login;
        const homePath = isIframe ? routes.iframeCampaignsList : routes.home;

        const navigateTo = isLoginPage ? homePath : fromUrl || homePath;
        createSnackbar(dictionary.loginSuccessfully, { variant: 'success' });
        navigate(navigateTo, { replace: true });
      }
    } catch (e) {
      const errorResponse = (e as BaseCallbackError).response;
      const errorMessage = errorResponse?.data?.detail;
      setIsLoading(false);
      setError('mfa_code', { type: 'error', message: errorMessage || dictionary.invalidMfaCode });
    }
  };

  return (
    <div css={classes.root}>
      <Column center alignCenter>
        <RobinLogo width={220} />
        <Expand height={50} />
        <div css={classes.title}>{dictionary.multiFactorAuth}</div>
        <CounterMfaValidation />
        <FormProvider {...methods}>
          <form css={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <TextFieldWithUseForm
              label={dictionary.multiFactorAuth}
              type="number"
              name="mfa_code"
              rules={{
                required: dictionary.required,
                minLength: {
                  message: dictionary.atLeast6Characters,
                  value: 6,
                },
              }}
              contained
            />
            <hr />
            <Button
              label={dictionary.submit}
              submit
              icon={isLoading ? <ClipLoader color="currentColor" size={18} /> : undefined}
            />
          </form>
        </FormProvider>
        <SmallLanguageSwitch />
      </Column>
    </div>
  );
};
