import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import {
  type CandidateOutreachSendMethod,
  NotifyCandidateData,
  NotifyCandidateMethod,
} from 'clients/CampaignsClient/CampaignsClient.types';
import { userClient } from 'clients/UserClient/UserClient';
import { encodeBase64 } from 'helpers/base64';
import { isAxiosError } from 'helpers/clientHelpers';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useContext } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { BadRequestException } from 'shared/exceptions/BadRequestException';
import { IndeedNoCreditsAvailableException } from 'shared/exceptions/IndeedNoCreditsAvailableException';
import { useBrowserExtension } from 'shared/hooks';
import { candidateHistoryQuerySetValue } from 'shared/hooks/useBrowserExtension/useCandidateHistoryQuery.setValue';
import { sharedUtils } from 'utils';
import { MailInputValue } from '../types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';
import { WaitBeforeRemove } from './useWaitBeforeRemove';
import { useContextSelector } from 'use-context-selector';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { useIndeedConfig } from 'shared/hooks/useBrowserExtension/indeed/useIndeedConfig';
import { useIndeedSendMessageMutation } from 'shared/hooks/useBrowserExtension/indeed/useIndeedSendMessageMutation';

export const useContact = (params: WrappedHookParams) => {
  const campaignName = useContextSelector(SelectedCampaignContext, (s) => s.campaign?.name ?? '');
  const { dictionary, language } = useContext(LocalizationContext);
  const { creditBalance } = useIndeedConfig();
  const { currentUser, mailSignOff } = useContext(UserContext);
  const {
    sendLinkedinConnectionRequest,
    sendLinkedinProMessage,
    sendLinkedinMessage,
    existsLinkedinConversationWithCandidate,
    existsLinkedinInmailConversationWithCandidate,
  } = useBrowserExtension();
  const indeedSendMessageMutation = useIndeedSendMessageMutation();

  return useCandidateAction<ContactHookCallbackParams>(
    async ({ candidate, data, sendMethod, extras }) => {
      let thread_id = undefined;

      if (sendMethod === 'linkedin') {
        const candidateConversation = await existsLinkedinConversationWithCandidate(
          candidate.es_person_id_without_platform,
        );

        if (candidateConversation !== undefined) {
          const response = await sendLinkedinMessage({
            text: sharedUtils.htmlToPlainText(data.body ?? ''),
            candidateConversation,
          });

          if (response.status === 200) {
            thread_id = encodeBase64(`${currentUser?.id}-${candidate.es_person_id}`);
          }
        } else {
          const response = await sendLinkedinConnectionRequest({
            ...(data.body !== undefined && {
              customMessage: sharedUtils.htmlToPlainText(data.body),
            }),
            inviteeProfileUrn: `urn:li:fsd_profile:${candidate.es_person_id_without_platform}`,
          });

          if (response.status === 200) {
            thread_id = encodeBase64(`${currentUser?.id}-${candidate.es_person_id}`);
          }
        }
      } else if (sendMethod === 'indeed' && currentUser) {
        if (creditBalance === 0) {
          throw new IndeedNoCreditsAvailableException(dictionary.noCreditsAvailable);
        }

        if (!extras?.indeedProjectId) {
          throw TypeError('indedeProjectId must be provided');
        }

        const tenant = await userClient.getTenantCurrentUser();

        await indeedSendMessageMutation.mutateAsync({
          candidateId: candidate.es_person_id_without_platform,
          senderName: mailSignOff.fullName as string,
          senderCompany: `${tenant.data.name}`,
          message: data.body as string,
          jobTitle: data.subject as string,
          description: '...',
          projectKey: extras.indeedProjectId,
        });

        const sanitizedName = candidate.name.split(' ').join('');
        const encodedName = encodeURIComponent(sanitizedName).replace(/%([0-9A-F]{2})/g, (_, p1) =>
          String.fromCharCode(parseInt(p1, 16)),
        );
        const base64Name = btoa(encodedName);

        thread_id = `${tenant.data.id}_${base64Name}`;
      } else if (sendMethod === 'linkedin_inmail') {
        const threadId = await existsLinkedinInmailConversationWithCandidate(candidate.es_person_id_without_platform);

        const response = await sendLinkedinProMessage({
          candidate_id: candidate.es_person_id_without_platform,
          subject: data.subject as string,
          body: data.body as string,
          signature: mailSignOff.fullName as string,
          mailThread: threadId,
        });

        if (response.status === 201) {
          if (!threadId) {
            const jsonData = JSON.parse(response.body);
            thread_id = jsonData.elements[0].id.split(':ts_mail:')[1];
          } else {
            thread_id = threadId;
          }
        }
      }

      function sanitizeText(text: string = '') {
        let value = text;
        const allowedCharacters = /^[a-zA-Z0-9\s&*():;'"!@\?\{\}]*$/;

        if (!allowedCharacters.test(value)) {
          const cleanedValue = value.replace(/[^a-zA-Z0-9\s&*():;'"!@\?\{\}]/g, '');
          value = cleanedValue;
        }

        return value;
      }

      const notifyCandidateData: NotifyCandidateData = {
        message: data.body,
        sign_off:
          sendMethod !== 'werknl'
            ? data.signOff || [mailSignOff?.fullName, mailSignOff?.phoneNumber].filter(Boolean).join('\n')
            : mailSignOff?.fullName,
        subject: sendMethod === 'werknl' ? sanitizeText(data.subject) : data.subject,
        email: data.email,
        phone_number: sendMethod === 'werknl' ? data.phoneNumber?.replace(/\D/g, '') : data.phoneNumber,
        website: data.website,
        status: 'success',
        provider_thread_id: thread_id,
      };

      const attachments =
        sendMethod === 'email' && extras?.attachments && extras.attachments.length > 0 ? extras.attachments : undefined;

      await campaignsClient.notifyCandidate({
        campaign_id: candidate.campaignId,
        candidate_id: candidate.es_person_id,
        send_method: sendMethod,
        data: notifyCandidateData,
        attachments,
      });
    },
    {
      onSuccess: ({ fcParams: { candidate, sendMethod, data, options: { waitBeforeRemoveFromList } = {} }, gtm }) => {
        gtm.contactCandidate(candidate.campaignId, candidate.es_person_id);
        gtm.contactSentMessage(candidate.campaignId, candidate.es_person_id, sendMethod);

        return async ({ candidateOrchestrator: co, pageContext }) => {
          candidateHistoryQuerySetValue({
            language,
            candidate,
            currentUser,
            campaignName,
            content: { message: data.body, signoff: data.signOff, subject: data.subject },
            sendMethod: sendMethod as CandidateOutreachSendMethod,
          });

          if (pageContext === PageContext.Campaign) {
            if (candidate.status === CANDIDATE_STATUS.NEW) {
              co.campaign.decreaseStatOf.currentJobBoard();
            }

            if (!candidate.reasons.includes('contacted')) {
              co.campaign.update.labels.add('contacted');
            }

            if ([CANDIDATE_STATUS.MATCHED, CANDIDATE_STATUS.CONTACTED].every((s) => s !== candidate.status)) {
              const handeRemoveFromList = () => {
                co.campaign.update.locally({ status: CANDIDATE_STATUS.CONTACTED });
                co.campaign.remove.fromCurrentList();
                co.campaign.reset.contactList();
                co.campaign.decreaseStatOf.currentList();
                co.campaign.increaseStatOf.contactList();
              };

              if (waitBeforeRemoveFromList) {
                waitBeforeRemoveFromList().then(({ removeFromList }) => {
                  if (removeFromList) {
                    handeRemoveFromList();
                  }
                });
              } else {
                handeRemoveFromList();
              }
            }
          } else if (pageContext === PageContext.RatedCandidates) {
            if (!candidate.reasons.includes('contacted')) {
              co.ratedCandidates.update.labels.add('contacted');
            }

            if ([CANDIDATE_STATUS.MATCHED, CANDIDATE_STATUS.CONTACTED].every((s) => s !== candidate.status)) {
              const handeRemoveFromList = () => {
                co.ratedCandidates.update.locally({ status: CANDIDATE_STATUS.CONTACTED });
                co.ratedCandidates.remove.fromCurrentList();
                co.ratedCandidates.reset.contactList();
                co.ratedCandidates.decreaseStatOf.currentList();
                co.ratedCandidates.increaseStatOf.contactList();
              };

              if (waitBeforeRemoveFromList) {
                waitBeforeRemoveFromList().then(({ removeFromList }) => {
                  if (removeFromList) {
                    handeRemoveFromList();
                  }
                });
              } else {
                handeRemoveFromList();
              }
            }
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            if (!candidate.reasons.includes('contacted')) {
              co.share.update.labels.add('contacted');
            }

            if (candidate.status !== CANDIDATE_STATUS.MATCHED) {
              co.share.moveCandidateTo.contactList();
            }
          }
        };
      },
      onError: ({ fcParams: { candidate, sendMethod }, gtm }) => {
        gtm.contactCandidateFailed(candidate.campaignId, candidate.es_person_id, sendMethod);

        return ({ data }) => {
          if (isAxiosError(data) && data.response?.status === 400 && sendMethod === 'email') {
            throw new BadRequestException();
          }

          throw data;
        };
      },
    },
  )(params);
};

export type ContactHookCallbackParams = {
  data: MailInputValue;
  sendMethod: NotifyCandidateMethod;
  extras?: {
    indeedProjectId?: string;
    attachments?: File[];
  };
  options?: {
    waitBeforeRemoveFromList?: WaitBeforeRemove;
  };
};
