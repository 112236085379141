import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  fileSearchIcon: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[0]};
    color: ${theme.colors.neutral.variant[80]};
    padding: 4px;
    border-radius: 16px;
    border: 1px solid ${theme.colors.neutral.variant[10]};
    height: calc(100% - 72px);
    max-height: 100vh;
    box-shadow: 0px 0px 5px 3px rgba(67, 83, 98, 0.15);
    z-index: 100;
  `,
  settingsIcon: ({ theme }) => css`
    color: ${theme.colors.neutral.variant[80]};
    width: 235px;
    height: 300px;
    z-index: 100;
    margin-top: 132px;
    display: flex;
  `,
  fileSearchIconArrow: ({ theme }) => css`
    fill: ${theme.colors.neutral.variant[0]};
    top: 10px !important;
  `,
  settingsContent: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[0]};
    box-shadow: 0px 0px 5px 3px rgba(67, 83, 98, 0.15);
    border-radius: 16px;

    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    padding-bottom: 8px;
  `,
  header: css`
    padding: 16px 14px 8px;
  `,
  divider: ({ theme }) => css`
    box-sizing: border-box;
    display: flex;
    width: calc(100% - 12px);
    height: 1px;
    margin: 8px 6px;
    background-color: ${theme.colors.neutral.variant[30]};
  `,
  tooltip: css`
    background-color: rgb(47, 62, 76);
    color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 3px 9px;
    z-index: 1000;
    display: flex;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
