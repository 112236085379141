import { logger } from 'config/logger';
import { useGTM } from 'hooks/gtm';
import type { Filters } from './types';

export const useSendFilterChangesToGTM = () => {
  const gtm = useGTM();

  return ({ campaignId, newFilters, oldFilters }: { campaignId: number; newFilters: Filters; oldFilters: Filters }) => {
    try {
      const { aiJobTitlesAdded, aiJobTitlesRemoved, manualJobTitlesAdded, manualJobTitlesRemoved } = processJobTitles({
        newFilters,
        oldFilters,
      });

      if (aiJobTitlesAdded + manualJobTitlesAdded > 0) {
        gtm.campaignJobTitlesAdded({
          campaignId,
          aiJobTitles: aiJobTitlesAdded,
          manualJobTitles: manualJobTitlesAdded,
        });
      }

      if (aiJobTitlesRemoved + manualJobTitlesRemoved > 0) {
        gtm.campaignJobTitlesRemoved({
          campaignId,
          aiJobTitles: aiJobTitlesRemoved,
          manualJobTitles: manualJobTitlesRemoved,
        });
      }

      const { newSkillAdded, newSkillRemoved } = processSkills({ newFilters, oldFilters });

      if (newSkillAdded > 0) {
        gtm.campaignSkillsAdded({ campaignId, length: newSkillAdded });
      }

      if (newSkillRemoved > 0) {
        gtm.campaignSkillsRemoved({ campaignId, length: newSkillRemoved });
      }

      if (oldFilters.job_title_type !== newFilters.job_title_type) {
        gtm.campaignRecentExperienceAdjusted({ campaignId });
      }

      const oldLocation = oldFilters.locations.at(0);
      const newLocation = newFilters.locations.at(0);

      if (oldLocation?.key !== newLocation?.key) {
        gtm.campaignLocationAdjusted({ campaignId });
      }

      if (oldLocation?.distance !== newLocation?.distance) {
        gtm.campaignRadiusAdjusted({ campaignId });
      }
    } catch (e) {
      logger.error({
        error: e,
        customMessage: 'Error when trying to send search filters info to GTM',
      });
    }
  };
};

const processJobTitles = ({ newFilters, oldFilters }: { newFilters: Filters; oldFilters: Filters }) => {
  const oldJobTitles = oldFilters.job_titles;
  const newJobTitles = newFilters.job_titles;

  const newJobTitlesAdded = newJobTitles.filter((jt) => !oldJobTitles.some((ojt) => ojt.key === jt.key));
  const newJobTitlesRemoved = oldJobTitles.filter((ojt) => !newJobTitles.some((jt) => jt.key === ojt.key));

  const aiJobTitlesAdded = newJobTitlesAdded.filter((jt) => jt.isSynonym).length;
  const manualJobTitlesAdded = newJobTitlesAdded.length - aiJobTitlesAdded;

  const aiJobTitlesRemoved = newJobTitlesRemoved.filter((jt) => jt.isSynonym).length;
  const manualJobTitlesRemoved = newJobTitlesRemoved.length - aiJobTitlesRemoved;

  return { aiJobTitlesAdded, manualJobTitlesAdded, aiJobTitlesRemoved, manualJobTitlesRemoved };
};

const processSkills = ({ newFilters, oldFilters }: { newFilters: Filters; oldFilters: Filters }) => {
  const oldSkill = oldFilters.skills;
  const newSkill = newFilters.skills;

  const newSkillAdded = newSkill.filter((jt) => !oldSkill.some((ojt) => ojt.key === jt.key)).length;
  const newSkillRemoved = oldSkill.filter((ojt) => !newSkill.some((jt) => jt.key === ojt.key)).length;

  return { newSkillAdded, newSkillRemoved };
};
