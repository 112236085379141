export const countUnreadIndeedMessagesPayload = {
  operationName: 'UnreadConversationCount',
  variables: {
    input: {
      conversationFilter: {
        contexts: ['APPLICATION', 'RESUME_CONTACT', 'HQM', 'HQM_PREAPPLY'],
      },
      maxCountLimit: 100,
    },
  },
  extensions: {},
  query:
    'query UnreadConversationCount($input: GetUnreadConversationCountInput!) {\n  getUnreadConversationCount(input: $input) {\n    unreadConversationCount\n    __typename\n  }\n}\n',
};

export const getNewIndeedMessagesPayload = (take: number) => {
  return {
    extensions: {},
    operationName: 'FindConversationsByContext',
    query:
      'query FindConversationsByContext($contexts: [ConversationContext!], $scope: [ConversationScopeInput!], $before: String, $after: String, $last: Int = 50) {\n  findConversations(\n    input: {filter: {contexts: $contexts, includedLabels: [], excludedLabels: [], metadata: [], scope: $scope}}\n    last: $last\n    before: $before\n    after: $after\n  ) {\n    pageInfo {\n      ...FindConversationsPageInfoFragment\n      __typename\n    }\n    conversations {\n      ...ConversationSnippetFragment\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment FindConversationsPageInfoFragment on PageInfo {\n  endCursor\n  hasNextPage\n  hasPreviousPage\n  startCursor\n  __typename\n}\n\nfragment ConversationSnippetFragment on Conversation {\n  id\n  context\n  lastEvent {\n    type\n    publicationDateTime\n    messageBody\n    messagePreview\n    id\n    attachments {\n      fileName\n      __typename\n    }\n    author {\n      accountKey\n      __typename\n    }\n    __typename\n  }\n  locks {\n    primary\n    reason\n    timestamp\n    __typename\n  }\n  userReadsInfo {\n    unreadCount\n    __typename\n  }\n  participants {\n    accountKey\n    externalParticipantId\n    participantName\n    role\n    __typename\n  }\n  scope {\n    key\n    value\n    __typename\n  }\n  userLabelInfo {\n    labels\n    __typename\n  }\n  __typename\n}\n',
    variables: {
      last: take,
      contexts: ['RESUME_CONTACT'],
    },
    contexts: ['RESUME_CONTACT'],
    last: take,
  };
};
