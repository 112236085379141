import { Styles, css, verticalScrollCss, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css`
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 488px;
    width: 100%;
  `,
  header: ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.neutral.gray[20]};
    padding-bottom: 13px;
  `,
  statusesList: ({ theme }) => css`
    margin-top: 13px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    border-bottom: 1px solid ${theme.colors.neutral.gray[20]};
    padding-bottom: 13px;
    max-height: 270px;
    overflow: hidden auto;

    ${verticalScrollCss(theme)}
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 13px;
  `,
  footerConfirmed: css`
    display: flex;
    margin-top: 22px;
    align-items: center;
    justify-content: end;
    width: 100%;
  `,
  statusConfirmed: css`
    display: flex;
    gap: 9px;
  `,
  atsList: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    padding-top: 13px;
  `,
  atsItem: ({ theme }) => css`
    padding: 11px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.neutral.variant[10]};
    }

    &:active {
      background-color: ${theme.colors.neutral.variant[20]};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  `,
  loading: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    margin-top: 80px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
