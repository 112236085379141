import { useMutation } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { userClient } from 'clients/UserClient/UserClient';
import { logger } from 'config/logger';
import { queryClient } from 'config/queryClient';
import { QueryKey } from 'types/query';
import { useIndeedConfig } from './useIndeedConfig';
import { isObject } from 'helpers/helpers';

type User = {
  email: string;
  accountKey: string;
  userTrackingAccountKey: string;
  permissionSetIds: number[];
  status: string;
  subscriptionsTotal: number;
  subscriptionContactsTotal: number;
  advertiserWhereAccessGranted: {
    name: string;
    key: string;
  };
};

type UserListReponse = {
  authenticatedUser: {
    email: string;
    canAccessMultipleAdvertisers: boolean;
    canAccessHierarchies: boolean;
  };
  pageData: {
    usersTableRows: User[];
  };
};

type SubscriptionListResponse = {
  potentialAssignees: PotentialAssignee[];
  subscriptions: Subscription[];
};

type PotentialAssignee = {
  emailAddress: string;
  accountKey: string;
  moderated: boolean;
};

type Subscription = {
  assigneeKey: string;
  status: string;
  key: string;
};

export const useIndeedUpdateAndFetchUsersListMutation = () => {
  const indeedConfig = useIndeedConfig();

  return useMutation(async () => {
    if (!indeedConfig.isLoggedIn) {
      const { data: licenses } = await userClient.getIndeedLicense();

      return licenses;
    }

    const licenses = await getUsersList({ csrf: indeedConfig.csrfToken });
    const userEmails = licenses.pageData.usersTableRows.filter((u) => u.subscriptionsTotal > 0).map((u) => u.email);
    const { data: updatedLicenses } = await userClient.updateIndeedLicenseList(userEmails);

    try {
      const subscriptionStatuses = [] as Promise<unknown>[];
      const subscription = await getSubscriptionsList();

      subscription.subscriptions.forEach((s) => {
        const user = subscription.potentialAssignees.find((pa) => pa.accountKey === s.assigneeKey);
        const updatedLicense = updatedLicenses.find(
          (ul) => user && ul.indeed_license_id !== indeedConfig.email && ul.indeed_license_id === user.emailAddress,
        );

        if (updatedLicense && updatedLicense.status !== s.status) {
          subscriptionStatuses.push(
            userClient.updateIndeedLicenseStatus(updatedLicense.id, s.status === 'ACTIVE' ? 'ACTIVE' : 'EXPIRED'),
          );
        }
      });

      await Promise.all(subscriptionStatuses);
    } catch (e) {
      console.error(e);
      logger.error({ error: e, customMessage: 'Error when updating team licenses from indeed admin user' });
    }

    const currentLicense = updatedLicenses.find((l) => l.indeed_license_id === indeedConfig.email);

    if (currentLicense) {
      if (currentLicense.status !== 'EXPIRED' && !indeedConfig.hasActiveSubscription) {
        await userClient.updateIndeedLicenseStatus(currentLicense.id, 'EXPIRED');

        await queryClient.invalidateQueries([QueryKey.useCurrentUserQuery], { exact: false });
      }

      if (currentLicense.status !== 'ACTIVE' && indeedConfig.hasActiveSubscription) {
        await userClient.updateIndeedLicenseStatus(currentLicense.id, 'ACTIVE');

        await queryClient.invalidateQueries([QueryKey.useCurrentUserQuery], { exact: false });
      }

      const { data: licenses } = await userClient.getIndeedLicense();

      return licenses;
    }

    return updatedLicenses;
  });
};

const getUsersList = async ({ csrf }: { csrf: string }): Promise<UserListReponse> => {
  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://account.indeed.com/api/v1/pagedata/users?from=gnav-one-host&hl=en_US&co=US',
    method: 'GET',
    headers: { 'x-csrf-token': csrf },
  });

  return JSON.parse(response.body);
};

const getSubscriptionsList = async (): Promise<SubscriptionListResponse> => {
  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://resumes.indeed.com/rpc/subscription/admin',
    method: 'GET',
  });

  if (isObject(response) && response.status === 403) {
    return { potentialAssignees: [], subscriptions: [] };
  }

  return JSON.parse(response.body);
};
