import { RobinLogo, RobinLogoText } from '@recruitrobin/robin-theme/web-icons';
import { routes } from 'config';
import { generateDtiAttribute } from 'helpers/helpers';
import { useGTM } from 'hooks/gtm';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Logo.styles';
import { LogoProps } from './types';

const Logo = ({ to = routes.home, variant = 'default' }: LogoProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const gtm = useGTM();

  const onClickLogo = useCallback(() => {
    gtm.clickedRobinLogo();
    navigate(to);
  }, []);

  return (
    <div css={classes.root} onClick={onClickLogo} {...generateDtiAttribute('logo')}>
      {variant === 'text' ? (
        <RobinLogoText height={24} color={(c) => c.neutral.variant[80]} />
      ) : (
        <RobinLogo height={24} />
      )}
    </div>
  );
};

export default Logo;
