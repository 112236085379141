import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { queryClient } from 'config/queryClient';
import { TEAM_VIEW_RATED_CANDIDATES } from 'hooks/search/useRatedCandidatesQuery';
import { useLocalStorage } from 'hooks/shared';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { CampaignStats, CampaignTab } from './SelectedCampaignContext.types';

export const useRatedCandidatesStats = (): { stats: CampaignStats; isLoading: boolean } => {
  const pageContext = usePageContext();
  const { value: showTeamView } = useLocalStorage(TEAM_VIEW_RATED_CANDIDATES, true);

  const { data, isFetching } = useQuery(
    [QueryKey.useRatedCandidatesStats, { showTeamView }],
    async () => {
      if (pageContext !== PageContext.RatedCandidates) return null;

      const { data: stats } = await campaignsClient.getTenantRatedCandidatesStats(showTeamView);

      return { ...stats };
    },
    { enabled: pageContext === PageContext.RatedCandidates },
  );

  return useMemo(() => ({ stats: data || initialStatsState, isLoading: isFetching }), [data, isFetching]);
};

export const initialStatsState: Record<CampaignTab, number> = {
  [CampaignTab.New]: 0,
  [CampaignTab.Shortlisted]: 0,
  [CampaignTab.Contacted]: 0,
  [CampaignTab.Matched]: 0,
  [CampaignTab.Hidden]: 0,
};

export const setStats = (callback: (data: CampaignStats) => CampaignStats, teamview: boolean) => {
  queryClient.setQueryData(
    [QueryKey.useRatedCandidatesStats, { showTeamView: teamview }],
    (data: CampaignStats | undefined) => {
      if (!data) throw Error('Set stats undefined data');

      return callback(data);
    },
  );
};
