import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  menuOptionSettings: ({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    cursor: pointer;
    padding-left: 14px;
    height: 48px;
    gap: 8px;
    border-radius: 8px;
    align-items: center;

    :hover {
      background-color: ${theme.colors.neutral.variant[10]};
    }
  `,
  selected: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[20]};
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
