import { SwitchProps } from './types';
import { switchClassName, useStyles } from './styles';
import { default as Typography } from 'components/ui/atoms/Typography';
import { default as Row } from 'components/ui/atoms/Row';

const Switch = ({
  className,
  label,
  onChange,
  checked = false,
  labelVariant = (v) => v.supporting.label,
  variant = 'default',
  disabled = false,
}: SwitchProps) => {
  const classes = useStyles({ checked, variant, disabled });

  return (
    <Row
      css={classes.root}
      className={className}
      gap={8}
      onClick={() => {
        onChange?.(!checked);
      }}
      tabIndex={0}>
      {label?.off && <Typography variant={labelVariant}>{label?.off}</Typography>}
      <div css={classes.switch} className={switchClassName} />
      {label?.on && <Typography variant={labelVariant}>{label?.on}</Typography>}
    </Row>
  );
};

export default Switch;
