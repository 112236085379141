import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { queryClient } from 'config/queryClient';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { CampaignStats, CampaignTab } from './SelectedCampaignContext.types';

export const useStats = (id: number | undefined): { stats: CampaignStats; isLoading: boolean } => {
  const pageContext = usePageContext();

  const { data, isFetching } = useQuery(
    [QueryKey.selectedCampaignContextUseStats, id],
    async () => {
      if (typeof id !== 'number') throw Error('id cannot be empty');

      if (pageContext === PageContext.RatedCandidates) return;

      const [{ data: campaign }, { data: stats }] = await Promise.all([
        campaignsClient.getCampaign(id),
        campaignsClient.getCampaignCandidatesStats(id),
      ]);

      return { ...stats, [CampaignTab.New]: campaign.total_candidates_count };
    },
    { enabled: typeof id === 'number' && pageContext === PageContext.Campaign },
  );

  return useMemo(() => ({ stats: data || initialStatsState, isLoading: isFetching }), [data, isFetching]);
};

export const initialStatsState: Record<CampaignTab, number> = {
  [CampaignTab.New]: 0,
  [CampaignTab.Shortlisted]: 0,
  [CampaignTab.Contacted]: 0,
  [CampaignTab.Matched]: 0,
  [CampaignTab.Hidden]: 0,
};

export const setStats = (id: number | undefined, callback: (data: CampaignStats) => CampaignStats) => {
  queryClient.setQueryData([QueryKey.selectedCampaignContextUseStats, id], (data: CampaignStats | undefined) => {
    if (!data) throw Error('Set stats undefined data');

    return callback(data);
  });
};
