import {
  arrow,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';
import { useStyles } from './MultiFloatingButton.styles';
import { useRef, useState } from 'react';
import { MultiFloatingButtonProps } from './MultiFloatingButton.types';

const MultiFloatingButton = ({
  buttonChildren,
  tooltipChildren,
  menuChildren,
  tooltipArrow = false,
  isIconButton = true,
}: MultiFloatingButtonProps) => {
  const arrowRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles({ menuOpen });

  const {
    refs: tooltipRefs,
    floatingStyles: tooltipFloatingStyles,
    context: tooltipContext,
  } = useFloating({
    placement: 'bottom-end',
    open: tooltipOpen,
    onOpenChange: setTooltipOpen,
    middleware: [
      offset(5),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const {
    refs: menuRefs,
    floatingStyles: menuFloatingStyles,
    context: menuContext,
  } = useFloating({
    transform: false,
    placement: 'bottom-end',
    open: menuOpen,
    onOpenChange: setMenuOpen,
    middleware: [offset(5)],
  });

  const { getReferenceProps: getTooltipReferenceProps, getFloatingProps: getTooltipFloatingProps } = useInteractions([
    useHover(tooltipContext),
    useDismiss(tooltipContext),
  ]);

  const { getReferenceProps: getMenuReferenceProps, getFloatingProps: getMenuFloatingProps } = useInteractions([
    useClick(menuContext),
    useDismiss(menuContext),
  ]);

  const ref = useMergeRefs([tooltipRefs.setReference, menuRefs.setReference]);
  const props = getTooltipReferenceProps(getMenuReferenceProps());

  return (
    <>
      <button ref={ref} {...props} css={[classes.root, isIconButton && classes.filterButton]}>
        {typeof buttonChildren === 'function' ? buttonChildren({ open: menuOpen }) : buttonChildren}
      </button>
      {tooltipOpen && !menuOpen && (
        <div
          ref={tooltipRefs.setFloating}
          style={{ ...tooltipFloatingStyles }}
          css={classes.tooltip}
          {...getTooltipFloatingProps()}>
          {tooltipChildren}
          {tooltipArrow && <FloatingArrow ref={arrowRef} css={classes.tooltipArrow} context={tooltipContext} />}
        </div>
      )}
      {menuOpen && (
        <div
          ref={menuRefs.setFloating}
          style={{ ...menuFloatingStyles }}
          {...getMenuFloatingProps()}
          css={classes.statusFilterMenu}>
          {menuChildren({ handleClose: () => setMenuOpen(false) })}
        </div>
      )}
    </>
  );
};

export default MultiFloatingButton;
