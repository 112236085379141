import { Typography } from '@recruitrobin/robin-theme/web-components';
import { UserAvatarProps } from './types';
import { useStyles } from './UserAvatar.styles';

const UserAvatar = ({ initials, pictureUrl, variant = 'default' }: UserAvatarProps) => {
  const classes = useStyles({ pictureUrl, variant });

  return (
    <div css={classes.root}>
      <Typography variant={(v) => v.heading[6]} color={(c) => c.neutral.variant[60]}>
        {!pictureUrl && initials}
      </Typography>
    </div>
  );
};

export default UserAvatar;
