import { Loader, Typography } from '@recruitrobin/robin-theme/web-components';
import { ChevronDown, ChevronUp } from '@recruitrobin/robin-theme/web-icons';
import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { MultiFloatingButton, Switch } from 'components/ui/atoms';
import Row from 'components/ui/atoms/Row';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './CampaignListHeader.styles';
import { useContainer } from './CampaignListHeader.useContainer';

const CampaignListHeader = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { teamview, setTeamview, setStatus, count, isLoading, status } = useContainer();
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <div css={[classes.flexDiv, classes.searchGap]}>
        <Typography variant={(v) => v.heading[5]} color={(c) => c.neutral.variant[80]}>
          {dictionary.searches}
        </Typography>
        <Typography css={[classes.searchCount]} color={(c) => c.neutral.variant[0]} variant={(v) => v.supporting.label}>
          {isLoading ? <Loader color={(c) => c.neutral.gray} size={16} css={classes.paddingLoader} /> : count}
        </Typography>
      </div>
      <div css={[classes.flexDiv, classes.filterGap]}>
        <Switch
          css={classes.switch}
          onChange={setTeamview}
          checked={teamview}
          label={{ off: dictionary.teamView.toUpperCase() }}
          labelVariant={(v) => v.supporting['label-tiny']}
          variant="smaller"
        />

        <MultiFloatingButton
          isIconButton={false}
          buttonChildren={({ open }) => (
            <Row gap={2} alignCenter contained>
              <Typography variant={(v) => v.supporting['label-tiny']}>{dictionary[status]}</Typography>
              {open ? <ChevronUp height={16} /> : <ChevronDown height={16} />}
            </Row>
          )}
          tooltipArrow={true}
          tooltipChildren={<Typography variant={(v) => v.supporting.helper}>{dictionary.filters}</Typography>}
          menuChildren={({ handleClose }) => (
            <>
              <div
                onClick={() => {
                  setStatus(CampaignOverviewStatus.active);
                  handleClose();
                }}
                css={classes.statusOptions}>
                <Typography variant={(v) => v.supporting.helper}>{dictionary.active}</Typography>
              </div>
              <div
                onClick={() => {
                  setStatus(CampaignOverviewStatus.inactive);
                  handleClose();
                }}
                css={classes.statusOptions}>
                <Typography variant={(v) => v.supporting.helper}>{dictionary.inactive}</Typography>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default CampaignListHeader;
