import type { AxiosError, AxiosPromise } from 'axios';
import type { Tenant } from 'clients/Admin';
import type { EmptyResponseCallback, PaginatedFilters, PaginatedResponse } from '../types';

/////////////////////////////
/// Generic types
/////////////////////////////

export type Error = {
  detail: string;
};

export type BaseCallbackError = AxiosError<Error>;

/////////////////////////////
/// Object types
/////////////////////////////

export enum UserRoles {
  Recruiter = 'RECRUITER',
  Admin = 'ADMIN',
}

export enum WerknlLayout {
  New = 'NEW',
  Legacy = 'LEGACY',
}

export enum IndeedLicenseEmailType {
  indeedSubscriptionGranted = 'indeedSubscriptionGranted',
  indeedSubscriptionWithdrawn = 'indeedSubscriptionWithdrawn',
  indeedSubscriptionWithdrawnSubscriptionAvailable = 'indeedSubscriptionWithdrawnSubscriptionAvailable',
  indeedSubscriptionConnectedAdmin = 'indeedSubscriptionConnectedAdmin',
  noIndeedSubscriptionConnectedAdmin = 'noIndeedSubscriptionConnectedAdmin',
}

export type UserResponse = {
  id: number;
  is_active: boolean;
  email: string;
  tenant_id: number;
  is_email_confirmed: boolean;
  should_change_password: boolean;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  is_staff?: boolean;
  indeed_license?: {
    created_at: string;
    id: number;
    indeed_license_id: string;
    status: 'ACTIVE' | 'EXPIRED';
  };
  tenant_code?: string;
  created_at?: string;
  last_login?: string;
  has_tutorial?: boolean;
  role?: UserRoles;
  ats_credential_id: number | null;
  team_id: number | null;
  tenant: Pick<Tenant, 'id' | 'code' | 'name' | 'payment_method' | 'privacy_policy_link'>;
  expo_push_token?: string | null;
  werknl_layout?: WerknlLayout;
};

export type CreateUserDto = Partial<Pick<UserResponse, 'phone_number' | 'ats_credential_id' | 'team_id'>> &
  Pick<
    UserResponse,
    | 'first_name'
    | 'last_name'
    | 'should_change_password'
    | 'email'
    | 'is_active'
    | 'tenant_id'
    | 'tenant_code'
    | 'indeed_license'
    | 'has_tutorial'
    | 'role'
    | 'werknl_layout'
  > & {
    password?: string;
    send_activation_email: boolean;
  };

export type UpdateUserDto = Pick<UserResponse, 'id'> & Omit<Partial<CreateUserDto>, 'send_activation_email'>;

export type UpdateCurrentUserDto = Omit<UpdateUserDto, 'id'>;

export type UserCredit = {
  total: number;
  used: number;
  left: number;
  percentage_used: number;
  display_text: string;
};

export type ResetPasswordParams = {
  password: string;
  reset_code: string;
  user_id: number;
  tenant_id: number | null;
};

export type SendPasswordResetCodeParams = {
  email: string;
};

export type ValidatePasswordResetCodeParams = {
  user_id: number;
  reset_code: string;
  tenant_id: number;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type LoginTenantParams = {
  login_secret: string;
};

export type AuthenticationTokenType = 'Bearer';

export type Authentication = {
  token_type: AuthenticationTokenType;
  access_token: string;
  expires_in: number;
  user_id: number;
  tenant_id: number;
  should_change_password: boolean;
  password_reset_code?: string;
};

export type EmailAndPasswordAuthentication = {
  tenant_id: number;
  user_id: number;
  email: string;
  should_change_password: boolean;
  password_reset_code?: string;
};

export type MfaAuthentication = {
  token_type: AuthenticationTokenType;
  access_token: string;
  expires_in: number;
  user_id: number;
  tenant_id: number;
  should_change_password: boolean;
  password_reset_code?: string;
};
export type ChangeCurrentUserPasswordParams = {
  password: string;
  new_password: string;
};

export type CredentialsParams = {
  email: string;
  password: string;
};

export type MfaCodeParam = {
  mfa_code: string;
  email: string;
};

export type GetFeatureTogglesParams = {
  tenant_id?: number;
};

export type FeatureToggle = {
  id?: number | null;
  name: string;
  enabled: boolean;
  value: string;
  tenant_id?: Tenant['id'];
  created_at?: string;
  updated_at?: string;
};

export type UserEmailSignature = {
  id: number;
  created_at: string;
  updated_at: string;
  signature_html: string;
  tenant: number;
  user: number;
};

export type IndeedLicense = {
  id: number;
  indeed_license_id: string;
  created_at: string;
  status: 'ACTIVE' | 'EXPIRED';
  user: {
    id: number;
    user_email: string;
    full_name: string;
  } | null;
};

export type IndeedLicenseAudit = {
  new_user: {
    id: number;
    user_email: string;
    full_name: string;
  } | null;
  old_user: {
    id: number;
    user_email: string;
    full_name: string;
  } | null;
  author: {
    id: number;
    user_email: string;
    full_name: string;
  };
  created_at: string;
};

/////////////////////////////
/// Handlers
/////////////////////////////

export type IndexUserHandler = (
  params: PaginatedFilters<UserResponse> & { ordering?: string },
) => AxiosPromise<PaginatedResponse<UserResponse>>;

export type CreateUserHandler = (params: CreateUserDto) => AxiosPromise<UserResponse>;

export type GetUserHandler = (id: number) => AxiosPromise<UserResponse>;

export type GetCurrentUserHandler = () => AxiosPromise<UserResponse>;

export type GetIndeedUserLicenseHandler = <T extends number | undefined = undefined>(
  id?: T,
) => AxiosPromise<T extends number ? IndeedLicense : IndeedLicense[]>;

export type UpdatedIndeedLicenseList = (licenses: string[]) => AxiosPromise<IndeedLicense[]>;

export type UpdateIndeedLicenseStatus = (
  license_id: number,
  status: IndeedLicense['status'],
) => AxiosPromise<IndeedLicense>;

export type AssociateIndeedLicenseHandler = (params: {
  id: number;
  user_id: number | null;
}) => AxiosPromise<IndeedLicense>;

export type GetIndeedLicenseAuditHandler = (user_id: number) => AxiosPromise<IndeedLicenseAudit[]>;

export type NotifyUserIndeedLicenseStatusChangedHandler = (params: {
  user_id: number;
  email_type: IndeedLicenseEmailType;
}) => AxiosPromise<{ success: boolean }>;

export type GetTenantCurrentUserHandler = () => AxiosPromise<Tenant>;

export type UpdateUserHandler = (params: UpdateUserDto) => AxiosPromise<UserResponse>;

export type UpdateUserWithTokenHandler = (params: UpdateUserDto, token: string) => AxiosPromise<UserResponse>;

export type DeleteUserHandler = (id: number) => EmptyResponseCallback;

export type GetCreditsHandler = () => AxiosPromise<UserCredit>;

export type UpdateCurrentUserHandler = (params: UpdateCurrentUserDto) => AxiosPromise<UserResponse>;

export type ResetPasswordHandler = (params: ResetPasswordParams) => EmptyResponseCallback;

export type SendPasswordResetCodeHandler = (params: SendPasswordResetCodeParams) => EmptyResponseCallback;

export type ValidatePasswordResetCodeHandler = (params: ValidatePasswordResetCodeParams) => EmptyResponseCallback;

export type LoginHandler = (props: LoginParams) => AxiosPromise<Authentication>;

export type LoginTenantHandler = (props: LoginTenantParams) => AxiosPromise<Authentication>;

export type ValidateEmailAndPasswordHandler = (
  props: CredentialsParams,
) => AxiosPromise<EmailAndPasswordAuthentication>;
export type ValidateMfaCodeHandler = (props: MfaCodeParam) => AxiosPromise<MfaAuthentication>;

export type ChangeCurrentUserPasswordHandler = (params: ChangeCurrentUserPasswordParams) => EmptyResponseCallback;

export type GetFeatureTogglesHandler = (params?: GetFeatureTogglesParams) => AxiosPromise<FeatureToggle[]>;

export type CreateFeatureTogglesHandler = (
  params: Partial<FeatureToggle> & Required<Pick<FeatureToggle, 'enabled' | 'tenant_id'>>,
) => AxiosPromise<FeatureToggle>;

export type UpdateFeatureTogglesHandler = (
  params: Partial<FeatureToggle> & Required<Pick<FeatureToggle, 'id' | 'enabled'>>,
) => AxiosPromise<FeatureToggle>;

export type DeleteFeatureTogglesHandler = (id: number) => EmptyResponseCallback;

export type GetUserEmailSignatureHandler = {
  (id: number): AxiosPromise<UserEmailSignature>;
  (): AxiosPromise<UserEmailSignature[]>;
};

export type CreateUserEmailSignatureHandler = (signature: string) => AxiosPromise<UserEmailSignature>;

export type UpdateUserEmailSignatureHandler = (id: number, signature: string) => AxiosPromise<UserEmailSignature>;

export type DeleteUserEmailSignatureHandler = (id: number) => AxiosPromise<UserEmailSignature>;
