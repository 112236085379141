import { Styles, css, extractShadow, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    height: 100%;
    display: flex;
  `,
  logo: css`
    cursor: pointer;
  `,
  sidebarIconsNavColumn: ({ theme }) => css`
    justify-content: space-between;
    background-color: ${theme.colors.neutral.variant[0]};
    z-index: 0;
    box-shadow: ${extractShadow((s) => s.robin2)};
  `,
  languageMenu: css`
    gap: 10px;
    display: grid;
    padding-bottom: 24px;
  `,
  languageMenuOptions: css`
    cursor: pointer;
    text-align: center;
  `,
  sidebarFixed: css`
    overflow: hidden;
    border-top-right-radius: 14px;
    z-index: 11;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
