import { Settings } from '@recruitrobin/robin-theme/web-icons';
import { useStyles } from './EnableRobinResumeModal.styles';
import { Switch } from 'components/ui/atoms';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useContext } from 'react';
import { Typography } from '@recruitrobin/robin-theme/web-components';
import { capitalize } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { atsIntegrationsClient } from 'clients/ATSIntegrationsClient/ATSIntegrationsClient';
import { userClient } from 'clients/UserClient/UserClient';
import { QueryKey } from 'types/query';
import { AvailableAts, ValidAts, AtsAvailableListProps } from './types';
import { queryClient } from 'config/queryClient';

export const AtsAvailableList = ({ tenantId, setAtsStatus, updateFeatureToggle }: AtsAvailableListProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  const { data: validAts } = useQuery<ValidAts[]>(
    [QueryKey.validAtsForRobinResume, { tenant_id: tenantId }],
    async () => {
      const { data: featureToggles } = await userClient.getFeatureToggles({ tenant_id: tenantId });
      const { data: ats } = await atsIntegrationsClient.getAvailableAtsToPullStatuses();

      const validAts = ats.map((ats) => {
        const featureToggleForRobinResume = featureToggles.find(
          (featureToggle) => featureToggle.name === `robin_resume_${ats}`,
        );

        return {
          name: ats as AvailableAts,
          id: featureToggleForRobinResume?.id || null,
          enabled: featureToggleForRobinResume?.enabled || false,
        };
      });

      return validAts;
    },
    { initialData: [], initialDataUpdatedAt: 0 },
  );

  async function handleShowStatus(ats: ValidAts) {
    setAtsStatus(ats);
  }

  async function handleDeactivateRobinResume(ats: ValidAts) {
    if (!ats.enabled) {
      return;
    }

    await updateFeatureToggle(ats);

    await queryClient.invalidateQueries([QueryKey.validAtsForRobinResume, { tenant_id: tenantId }]);
  }

  return (
    <div css={classes.atsList}>
      {validAts.map((ats) => (
        <div css={classes.atsItem} key={ats.name} onClick={() => handleShowStatus(ats)}>
          <div>
            <Settings size={16} color={(c) => c.neutral.variant[80]} />
            <Typography variant={(v) => v.body.short} color={(c) => c.neutral.variant[80]}>
              {capitalize(ats.name)}
            </Typography>
          </div>
          <div
            onClick={(event) => {
              if (ats.enabled) {
                event.stopPropagation();
              }
            }}>
            <Switch
              disabled={!ats.enabled}
              onChange={() => handleDeactivateRobinResume(ats)}
              checked={ats.enabled}
              label={{ off: dictionary.active }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
