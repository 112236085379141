const CAMPAIGN_FIRST_FETCH_SS = (id: number) => `CAMPAIGN_FIRST_FETCH_SS_${id}`;

export const campaignFirstFetchManager = {
  set(campaignId: number) {
    sessionStorage.setItem(CAMPAIGN_FIRST_FETCH_SS(campaignId), 'new');
  },
  verify(campaignId: number) {
    return sessionStorage.getItem(CAMPAIGN_FIRST_FETCH_SS(campaignId)) === 'new';
  },
  remove(campaignId: number) {
    sessionStorage.removeItem(CAMPAIGN_FIRST_FETCH_SS(campaignId));
  },
};
