import type { FeatureToggle } from 'clients/UserClient/types';

import { useQuery } from '@tanstack/react-query';
import { userClient } from 'clients/UserClient/UserClient';
import { useCallback } from 'react';
import { QueryKey } from 'types/query';
import { useCurrentUserQuery } from './useCurrentUserQuery';

export enum FeatureToggleType {
  FILTER_INDEED_CANDIDATES_BASED_ON_USER_LICENSE = 'FILTER_INDEED_CANDIDATES_BASED_ON_USER_LICENSE',
}

export type UseFeatureTogglesHook = () => {
  isFeatureToggleEnabled: (ft_name: string | ((ft_type: typeof FeatureToggleType) => FeatureToggleType)) => boolean;
  isLoading: boolean;
};

const useFeatureToggles: UseFeatureTogglesHook = () => {
  const { data: currentUser } = useCurrentUserQuery();

  const { data: featureToggles, isFetching: featureTogglesIsLoading } = useQuery(
    [QueryKey.useFeatureTogglesQuery],
    async () => {
      const { data: featureToggles } = await userClient.getFeatureToggles();

      return featureToggles;
    },
    { enabled: Boolean(currentUser), initialData: [], initialDataUpdatedAt: 0 },
  );

  const isFeatureToggleEnabled = useCallback<ReturnType<UseFeatureTogglesHook>['isFeatureToggleEnabled']>(
    (ft_name) => {
      const name = typeof ft_name === 'string' ? ft_name : ft_name(FeatureToggleType);

      const isFeatureToggleEnabled = featureToggles.some((feature) => feature.name === name && feature.enabled);

      if (name === 'search_templates') {
        return __legacy_validateSearchTemplatesToggle(featureToggles);
      }

      return isFeatureToggleEnabled;
    },
    [featureToggles, currentUser?.tenant.id],
  );

  return { isFeatureToggleEnabled, isLoading: featureTogglesIsLoading };
};

export default useFeatureToggles;

//#region Legacy feature toggles
// Needs to be studied if they can be removed. Theorically, they're useless because the feature(s) is/are already released

const __legacy_validateSearchTemplatesToggle = (featureToggles: FeatureToggle[]) => {
  const searchTemplateFeatureToggleSet = featureToggles.find((feature) => feature.name === 'search_templates');
  const isFeatureToggleEnabled = Boolean(searchTemplateFeatureToggleSet?.enabled);
  const searchTemplatesReleaseToggleEnabled = process.env.REACT_APP_SEARCH_TEMPLATES_RELEASE_TOGGLE === 'on';

  console.debug('[useFeatureToggles] release toggle "search_templates": ', {
    isFeatureToggleEnabled,
    isReleaseToggleEnabled: searchTemplatesReleaseToggleEnabled,
  });

  return (
    searchTemplateFeatureToggleSet?.enabled ||
    (searchTemplateFeatureToggleSet?.enabled !== false && searchTemplatesReleaseToggleEnabled)
  );
};
