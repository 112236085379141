import { ButtonGroup, LargeIconButton } from '@recruitrobin/robin-theme/web-components';
import {
  FileSearch_02,
  FileSearch_02Fill,
  FolderSearch,
  FolderSearchFill,
  LayoutLeft,
  LayoutLeftFill,
  PieChart_01,
  PieChart_01Fill,
  Settings_02,
} from '@recruitrobin/robin-theme/web-icons';
import { theme } from '@recruitrobin/robin-theme/web-styles';
import { Typography } from 'components/ui/atoms';
import { routes } from 'config';
import { useContext } from 'react';
import { AuthContext } from 'shared/contexts/AuthContext';
import { CandidateOutreachModalContext } from 'shared/contexts/CandidateOutreachModalContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useAuth } from 'shared/hooks';
import { useContextSelector } from 'use-context-selector';
import { useContainer } from './Sidebar.useContainer';
import { useStyles } from './SidebarNav.styles';
import { SidebarNavPages } from './SidebarNav.types';
import { useSelectedRoute } from './SidebarNav.useSelectedRoute';
import SidebarNavSettingsMenu from './SidebarNavSettingsMenu';
import SidebarOverlayFloating from './SidebarOverlayFloating';

const SIDEBAR_MENU_ICONS_SIZE = 20;

const SidebarNav = () => {
  const { isStaff } = useAuth();
  const classes = useStyles();
  const { navigateTo, verifyAndNavigateToSearchRoute } = useContainer();
  const { dictionary } = useContext(LocalizationContext);
  const { isOpen: isFixedOpen, setIsOpen: setIsFixedOpen } = useContext(SidebarOverlayFixedContext);
  const activeRoute = useSelectedRoute();
  const { activeTokenType, disposeImpersonatedToken } = useContext(AuthContext);
  const setCandidateOutreachModal = useContextSelector(
    CandidateOutreachModalContext,
    (s) => s.setCandidateOutreachModal,
  );

  const closeOutreachModalOnClick = (callback: () => void) => {
    setCandidateOutreachModal(null);
    callback();
  };

  return (
    <ButtonGroup gap={16}>
      <LargeIconButton
        selected={SidebarNavPages.Campaign === activeRoute}
        icon={(selected) => {
          return selected ? (
            <FileSearch_02Fill size={SIDEBAR_MENU_ICONS_SIZE} />
          ) : (
            <FileSearch_02 size={SIDEBAR_MENU_ICONS_SIZE} />
          );
        }}
        onClick={() =>
          closeOutreachModalOnClick(() => {
            verifyAndNavigateToSearchRoute();
            setIsFixedOpen(!isFixedOpen);
          })
        }
        tooltip={!isFixedOpen && <SidebarOverlayFloating />}
        tooltipStyle={classes.fileSearchIcon}
        tooltipArrowStyle={classes.fileSearchIconArrow}
        tooltipArrowStrokeStyle={theme.colors.neutral.variant[10]}
        tooltipArrowStrokeWidthStyle={1}
        customTooltipPosition={{ top: 64, left: 0 }}
        tooltipArrow
      />
      <LargeIconButton
        selected={SidebarNavPages.RatedCandidates === activeRoute}
        icon={(selected) =>
          selected ? (
            <FolderSearchFill size={SIDEBAR_MENU_ICONS_SIZE} />
          ) : (
            <FolderSearch size={SIDEBAR_MENU_ICONS_SIZE} />
          )
        }
        onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.ratedCandidates))}
        tooltipStyle={classes.tooltip}
        tooltip={<Typography variant="supporting.helper">{dictionary.candidates}</Typography>}
      />
      <LargeIconButton
        selected={SidebarNavPages.Dashboard === activeRoute}
        icon={(selected) =>
          selected ? <PieChart_01Fill size={SIDEBAR_MENU_ICONS_SIZE} /> : <PieChart_01 size={SIDEBAR_MENU_ICONS_SIZE} />
        }
        onClick={() => closeOutreachModalOnClick(() => navigateTo(routes.dashboard))}
        tooltipStyle={classes.tooltip}
        tooltip={<Typography variant="supporting.helper">{dictionary.dashboard}</Typography>}
      />
      <LargeIconButton
        icon={() => <Settings_02 size={SIDEBAR_MENU_ICONS_SIZE} />}
        onClick={() => undefined}
        tooltip={<SidebarNavSettingsMenu />}
        tooltipStyle={classes.settingsIcon}
        tooltipArrowStyle={classes.fileSearchIconArrow}
        tooltipArrowStrokeStyle={theme.colors.neutral.variant[10]}
        tooltipArrowStrokeWidthStyle={1}
        tooltipArrow
        selected={false}
      />
      {isStaff && (
        <LargeIconButton
          selected={SidebarNavPages.Admin === activeRoute}
          icon={(selected) =>
            selected ? <LayoutLeftFill size={SIDEBAR_MENU_ICONS_SIZE} /> : <LayoutLeft size={SIDEBAR_MENU_ICONS_SIZE} />
          }
          onClick={() =>
            closeOutreachModalOnClick(() => {
              if (activeTokenType === 'impersonate') {
                disposeImpersonatedToken();
              }

              navigateTo(routes.admin.dashboard);
            })
          }
          tooltipStyle={classes.tooltip}
          tooltip={<Typography variant="supporting.helper">{dictionary.adminPanel}</Typography>}
        />
      )}
    </ButtonGroup>
  );
};

export default SidebarNav;
