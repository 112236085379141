import { useQuery } from '@tanstack/react-query';
import { IndeedMessagesListResponse, IndeedUnreadConversationCountResponse } from './useUnreadIndeedMessages.types';
import { fetchIndeedGraphQL } from './utils';
import { useIndeedConfig } from './useIndeedConfig';
import { countUnreadIndeedMessagesPayload, getNewIndeedMessagesPayload } from './useUnreadIndeedMessages.payloads';
import useFeatureToggles from 'shared/hooks/useFeatureToggles';
import { QueryKey } from 'types/query';

const INTERVAL = 1000 * 10;
const INDEED_NEW_MESSAGES_TAKE_DEFAULT_VALUE = 50;

export const useCountUnreadIneedMessages = () => {
  const indeedConfig = useIndeedConfig();
  const { isFeatureToggleEnabled } = useFeatureToggles();
  const isFetchNewIndeedMessagesAvailable = isFeatureToggleEnabled('INDEED_MESSAGE_RECEIVED');

  const { data: unreadCount, dataUpdatedAt } = useQuery(
    [QueryKey.countUnreadIndeedMessages],
    async () => {
      const jsonData: IndeedUnreadConversationCountResponse = await fetchIndeedGraphQL(
        countUnreadIndeedMessagesPayload,
        indeedConfig,
      );

      const unreadCount = jsonData.data.getUnreadConversationCount.unreadConversationCount;
      return unreadCount <= 0 ? 0 : unreadCount;
    },
    {
      enabled: isFetchNewIndeedMessagesAvailable && indeedConfig.isLoggedIn,
      refetchInterval: INTERVAL,
      initialData: 0,
      initialDataUpdatedAt: 0,
    },
  );

  return {
    unreadCount,
    dataUpdatedAt,
  };
};

export const useGetNewIndeedMessages = () => {
  const indeedConfig = useIndeedConfig();

  const { unreadCount, dataUpdatedAt } = useCountUnreadIneedMessages();

  const { data: newIndeedMessages } = useQuery(
    [QueryKey.getNewIndeedMessages, { dataUpdatedAt }],
    async () => {
      const jsonData: IndeedMessagesListResponse = await fetchIndeedGraphQL(
        getNewIndeedMessagesPayload(INDEED_NEW_MESSAGES_TAKE_DEFAULT_VALUE),
        indeedConfig,
      );
      const unreadMessages = jsonData.data.findConversations.conversations.filter((e) => {
        return e.userReadsInfo.unreadCount > 0;
      });

      return unreadMessages;
    },
    {
      enabled: indeedConfig.isLoggedIn && !!unreadCount && unreadCount > 0,
      initialData: [],
      initialDataUpdatedAt: 0,
    },
  );

  return {
    newIndeedMessages,
  };
};
