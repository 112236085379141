import { useContext, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { default as Modal } from 'shared/components/Modal';
import { useStyles } from './EnableRobinResumeModal.styles';
import { EnableRobinResumeModalProps, ValidAts } from './types';
import { Typography } from '@recruitrobin/robin-theme/web-components';

import { AtsAvailableList } from './AtsAvailableList';
import { AtsStatusesList } from './AtsStatusesList';
import { queryClient } from 'config/queryClient';
import { QueryKey } from 'types/query';
import { Switch } from 'components/ui/atoms';
import { userClient } from 'clients/UserClient/UserClient';

const EnableRobinResumeModal = ({ visible, onClose, tenantId }: EnableRobinResumeModalProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [atsStatus, setAtsStatus] = useState<ValidAts | null>(null);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);

  async function handleClose() {
    onClose('close-button');
    await queryClient.invalidateQueries([QueryKey.atsStatusesQuery, tenantId, atsStatus?.name]);
  }

  async function handleGoBack() {
    setAtsStatus(null);
    await queryClient.invalidateQueries([QueryKey.atsStatusesQuery, tenantId, atsStatus?.name]);
  }

  async function updateFeatureToggle(ats: ValidAts) {
    queryClient.setQueryData<ValidAts[]>([QueryKey.validAtsForRobinResume, { tenant_id: tenantId }], (oldValidAts) => {
      return oldValidAts?.map((ats1) => {
        if (ats1.name === ats.name) {
          return {
            ...ats,
            enabled: !ats.enabled,
          };
        }

        return ats1;
      });
    });

    if (ats.id) {
      await userClient.updateFeatureToggles({
        name: `robin_resume_${ats.name}`,
        value: '1',
        enabled: !ats.enabled,
        tenant_id: tenantId,
        id: ats.id,
      });
    } else {
      await userClient.createFeatureToggles({
        name: `robin_resume_${ats.name}`,
        value: '1',
        enabled: !ats.enabled,
        tenant_id: tenantId,
      });
    }
  }

  async function handleToggleRobinResume(ats: ValidAts) {
    if (!ats.enabled) {
      return;
    }

    setAtsStatus({
      ...ats,
      enabled: false,
    });
    await updateFeatureToggle(ats);

    await queryClient.invalidateQueries([QueryKey.validAtsForRobinResume, { tenant_id: tenantId }]);
  }

  return (
    <Modal
      title={atsStatus ? '' : dictionary.robinResume}
      visible={visible}
      onClose={handleClose}
      hideActions
      onBack={handleGoBack}
      shouldShowBackButton={!!atsStatus}>
      <div css={classes.root}>
        <div css={classes.header}>
          <Typography variant={(v) => v.body.short}>{dictionary.robinResumeDescription(atsStatus?.name)}</Typography>
          {atsStatus && (
            <Switch
              disabled={!atsStatus.enabled}
              onChange={() => handleToggleRobinResume(atsStatus)}
              checked={atsStatus.enabled}
              label={{ off: dictionary.active }}
            />
          )}
        </div>

        {atsStatus ? (
          <AtsStatusesList
            isLoadingStatuses={isLoadingStatuses}
            setIsLoadingStatuses={setIsLoadingStatuses}
            ats={atsStatus}
            updateFeatureToggle={updateFeatureToggle}
            tenantId={tenantId}
            setAtsStatus={setAtsStatus}
          />
        ) : (
          <AtsAvailableList updateFeatureToggle={updateFeatureToggle} tenantId={tenantId} setAtsStatus={setAtsStatus} />
        )}
      </div>
    </Modal>
  );
};

export default EnableRobinResumeModal;
