import { Loader, Select, SelectOptionType } from '@recruitrobin/robin-theme/web-components';
import { ChevronDown, XCircle } from '@recruitrobin/robin-theme/web-icons';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useDebounce } from 'react-use';

import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { FieldLabelWrapper } from 'components/ui/molecules';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { QueryKey } from 'types/query';
import { SearchCampaignsProps } from './types';

/**
 * @deprecated Do not use this component as reference, it's just here because
 * the ApproveCandidateToAnotherSearchModal componente itself was made using outdated dependencies
 * so it's easier to create a skeleton from scratch than refactor it to handle loadings
 */
export const SearchCampaigns = ({ handleChange: handleParentChange }: SearchCampaignsProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const [selected, setSelected] = useState<SelectOptionType<number>>();
  const [search, setSearch] = useState('');
  const [rawSearchInput, setRawSearchInput] = useState('');

  useDebounce(() => setSearch(rawSearchInput), 250, [rawSearchInput]);

  const {
    data: options,
    isFetching,
    isError,
  } = useQuery(
    [QueryKey.getMyCampaigns, { name: search }],
    async () => {
      const { data } = await campaignsClient.getCampaignsPage({ limit: 10, offset: 0, name: search });
      return data.results.map<SelectOptionType<number>>((campaign) => ({
        value: campaign.id,
        label: campaign.name,
      }));
    },
    { initialData: [], initialDataUpdatedAt: 0 },
  );

  const handleChange = useCallback((searchCampaign?: SelectOptionType<number>) => {
    if (!searchCampaign) {
      return;
    }
    setSelected(searchCampaign);
    handleParentChange(searchCampaign.value);
    setRawSearchInput(searchCampaign.label);
  }, []);

  return (
    <FieldLabelWrapper label={dictionary.searchForCampaign} tooltipLabel={dictionary.searchForCampaign}>
      <Select
        text={rawSearchInput}
        onTextChange={setRawSearchInput}
        options={options}
        value={selected}
        onChange={handleChange}
        contained
        autocomplete
        placeholder={dictionary.searchForCampaign}
        startIcon={() =>
          isFetching ? <Loader size={18} /> : isError ? <XCircle color={(c) => c.tertiary.red[90]} size={18} /> : <></>
        }
        endIcon={() => <ChevronDown size={18} />}
      />
    </FieldLabelWrapper>
  );
};
