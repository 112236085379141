import type { UserResponse } from 'clients/UserClient/types';

import { useQuery } from '@tanstack/react-query';
import { userClient } from 'clients/UserClient/UserClient';
import { useContext } from 'react';
import { AuthContext } from 'shared/contexts/AuthContext';
import { QueryKey } from 'types/query';
import { CustomUseQueryOptions } from './types';

window.currentUserRefs = {};

export const useCurrentUserQuery = (
  options?: CustomUseQueryOptions<UserResponse, (QueryKey | { activeToken: string | null })[] | QueryKey[]>,
) => {
  const { activeToken } = useContext(AuthContext);

  const data = useQuery(
    [QueryKey.useCurrentUserQuery, { activeToken }],
    async () => {
      const { data: user } = await userClient.getCurrent();

      return user;
    },
    options,
  );

  return data;
};
