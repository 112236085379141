import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  `,
  rootSuccess: ({ theme }) => css`
    color: ${theme.colors.primary.fuchsia[60]};
    background-color: ${theme.colors.primary.fuchsia[20]};
  `,
  rootError: ({ theme }) => css`
    color: ${theme.colors.neutral.variant[40]};
    background-color: ${theme.colors.neutral.variant[10]};
  `,
  badge: () => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: 2px solid white;
    border-radius: 50%;
    top: 22px;
    left: 22px;
    transform: translate(50%, 50%);

    color: white;

    svg {
      margin: -4px;
    }
  `,
  badgeSuccess: ({ theme }) => css`
    background-color: ${theme.colors.secondary.emerald[40]};
  `,
  badgeError: ({ theme }) => css`
    background-color: ${theme.colors.tertiary.red[50]};
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
