import { LandingPageContext } from 'shared/contexts/LandingPageContext';
import { useContextSelector } from 'use-context-selector';
import LandingPageOverlay from './LandingPageOverlay';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';

export const LandingPageOverlayManager = () => {
  const isShowingLandingPage = useContextSelector(LandingPageContext, (state) => state.isShowingLandingPage);
  const pageContext = usePageContext();
  const isLandingPage = pageContext === PageContext.Home;

  if (isShowingLandingPage && !isLandingPage) {
    return <LandingPageOverlay />;
  } else {
    return;
  }
};

export default LandingPageOverlayManager;
