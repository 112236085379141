import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { useIndeedConfig } from './useIndeedConfig';

export async function fetchIndeedGraphQL(payload: any, indeedConfig: ReturnType<typeof useIndeedConfig>) {
  if (!indeedConfig.isLoggedIn) throw new Error('User must be logged to make requests to Indeed.');

  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://apis.indeed.com/graphql',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: indeedConfig.headers,
  });

  if (response.status !== 200) throw new Error('Failed to fetch Indeed.');

  return JSON.parse(response.body);
}
