import { Styles, css, extractTransition, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  content: ({ theme }) =>
    css({
      position: 'relative',
      borderRadius: 8,
      padding: '32px 40px',
      backgroundColor: theme.colors.neutral.variant[0],
      '&:focus-visible': {
        outline: 0,
      },
    }),
  closeButton: () =>
    css({
      position: 'absolute',
      top: 24,
      right: 24,
    }),
  header: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
  subtitle: () => css({}),
  body: () => css({}),
  actions: () =>
    css({
      display: 'flex',
      justifyContent: 'flex-end',
      '& > :not(:first-of-type)': {
        marginLeft: 8,
      },
    }),
  back: ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    padding: 4px;

    cursor: pointer;
    user-select: none;

    ${extractTransition((f) => f.cubic, { property: ['background-color', 'box-shadow'], duration: 240 })}

    &:hover {
      background-color: ${theme.colors.neutral.variant[10]};
      box-shadow: 0px 0px 0px 4px ${theme.colors.neutral.variant[10]};
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
