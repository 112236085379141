import { IconButton } from '@recruitrobin/robin-theme/web-components';
import { Maximize_01 } from '@recruitrobin/robin-theme/web-icons';
import { Row } from 'components/ui/atoms';
import { default as Modal } from 'components/ui/molecules/Modal';
import { Candidate } from 'model';
import { CandidateCVRender } from './CandidateCVRender';
import { useStyles } from './CandidateCVRender.styles';
import { useRequestCV } from './CandidateCVRender.useRequestCV';
import { useEffect, useState } from 'react';
import { CandidateActionButtons } from 'components/ui/molecules';
import { CandidateActionButtonsVariant } from 'components/ui/molecules/CandidateActionButtons/CandidateActionButtons.types';
import { CandidateContextProvider } from 'shared/contexts/CandidateContext/CandidateContext';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { useContextSelector } from 'use-context-selector';
import { CandidateOutreachModalContext } from 'shared/contexts/CandidateOutreachModalContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { usePageContext } from 'hooks/shared/usePageContext';

export const CandidateCVRenderArea = ({ candidate }: { candidate: Candidate }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const cv = useRequestCV(candidate);
  const pageContext = usePageContext();

  const selectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.selectedCandidate);
  const outreachMethod = useContextSelector(CandidateOutreachModalContext, (s) => s.type);
  const showExpandViewButton = pageContext === PageContext.Campaign || pageContext === PageContext.RatedCandidates;

  useEffect(() => {
    if (outreachMethod) {
      setVisible(false);
    }
  }, [outreachMethod]);

  useEffect(() => {
    if (visible && candidate.es_person_id !== selectedCandidate?.es_person_id) {
      setVisible(false);
    }
  }, [candidate, visible, selectedCandidate]);

  return (
    <Row css={classes.root} center contained>
      <CandidateCVRender candidate={candidate} cv={cv} />
      {showExpandViewButton && (
        <IconButton icon={<Maximize_01 size={14} />} onClick={() => setVisible(true)} style="bare" type="subtle" />
      )}

      <Modal visible={visible} onRequestClose={() => setVisible(false)} contentCss={classes.expandedCvViewModal}>
        <div css={classes.expandedCvViewWrapper}>
          <CandidateContextProvider candidate={candidate}>
            <CandidateActionButtons
              variant={CandidateActionButtonsVariant.candidateExpandedView}
              callback={() => setVisible(false)}
            />
            <CandidateCVRender candidate={candidate} cv={cv} />
          </CandidateContextProvider>
        </div>
      </Modal>
    </Row>
  );
};
