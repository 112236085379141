import { useQuery } from '@tanstack/react-query';
import { IndeedLicense, IndeedLicenseAudit, IndeedLicenseEmailType, UserRoles } from 'clients/UserClient/types';
import { userClient } from 'clients/UserClient/UserClient';
import { IndeedLicenseModal } from 'components/ui/molecules/IndeedLicenseModal';
import { IndeedLicenseModalType } from 'components/ui/molecules/IndeedLicenseModal/IndeedLicenseModal.types';
import { logger } from 'config/logger';
import { queryClient } from 'config/queryClient';
import { useIndeedAssociateLicenseMutation } from 'hooks/shared/useIndeedAssociateLicenseMutation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFeatureToggles } from 'shared/hooks';
import { useIndeedConfig } from 'shared/hooks/useBrowserExtension/indeed/useIndeedConfig';
import { useIndeedUpdateAndFetchUsersListMutation } from 'shared/hooks/useBrowserExtension/indeed/useIndeedUpdateAndFetchUsersListMutation';
import { useCurrentUserQuery } from 'shared/hooks/useCurrentUserQuery';
import { QueryKey } from 'types/query';
import { IndeedModalHandlers } from './useIndeedModals.types';

const LOGIN_TO_INDEED_LINK = 'https://secure.indeed.com/auth';
const LAST_AUDIT_INDEED_LS = 'LAST_AUDIT_INDEED_LS_V2';
const FIRST_INDEED_MODAL_LS = 'FIRST_INDEED_MODAL_LS_V2';

export const useIndeedModals = (): [IndeedModalHandlers, React.ReactNode] => {
  const [type, setType] = useState(IndeedLicenseModalType.indeedSubscriptionAvailable);
  const [isVisible, setIsVisible] = useState(false);

  const { isFeatureToggleEnabled } = useFeatureToggles();

  /// TODO: --START-- REMOVE AFTER INDEED LICENSE RELEASED
  const filterIndeedCandidatesBasedOnUserLicense = isFeatureToggleEnabled(
    (t) => t.FILTER_INDEED_CANDIDATES_BASED_ON_USER_LICENSE,
  );
  /// TODO: -- END -- REMOVE AFTER INDEED LICENSE RELEASED

  const indeedConfig = useIndeedConfig();
  const { data: currentUser } = useCurrentUserQuery();
  const indeedUpdateAndFetchUsersListMutation = useIndeedUpdateAndFetchUsersListMutation();
  const indeedAssociateLicenseMutation = useIndeedAssociateLicenseMutation();

  const {
    data: updatedLicenses,
    isFetching: isFetchingUpdatedLicenses,
    dataUpdatedAt,
  } = useQuery(
    [QueryKey.useIndeedModalsUpdatedLicenses, { indeedConfig }],
    async () => {
      if (!currentUser) return [];

      const licenses = await indeedUpdateAndFetchUsersListMutation.mutateAsync();

      return licenses;
    },
    { enabled: Boolean(currentUser), initialDataUpdatedAt: 0, initialData: [] },
  );

  const availableLicenses = useMemo(
    () => updatedLicenses.filter((l) => (!l.user || l.user.id === currentUser?.id) && l.status === 'ACTIVE'),
    [updatedLicenses, currentUser],
  );

  const { data: audit } = useQuery(
    [QueryKey.useIndeedModalsAudit, { currentUser, updatedLicenses }],
    async () => {
      if (!currentUser) return [];

      const { data: audit } = await userClient.getIndeedLicenseAudit(currentUser.id);

      return audit;
    },
    {
      enabled: Boolean(updatedLicenses.length > 0 && currentUser),
      refetchInterval: 300_000,
      initialData: [],
      initialDataUpdatedAt: 0,
    },
  );

  const verifyCurrentUserLicenseGranted = useCallback(
    ({
      currentUserId,
      lastAudit,
      availableLicenses,
    }: {
      currentUserId: number;
      lastAudit: IndeedLicenseAudit;
      availableLicenses: IndeedLicense[];
    }): IndeedLicenseModalType | undefined => {
      if (lastAudit.author.id !== currentUserId) {
        const isMe = lastAudit.new_user?.id === currentUserId;

        if (isMe) return IndeedLicenseModalType.indeedSubscriptionGranted;

        if (lastAudit.old_user) {
          const wasMe = lastAudit.old_user.id === currentUserId;

          if (wasMe) {
            if (availableLicenses.length > 0) {
              return IndeedLicenseModalType.indeedSubscriptionWithdrawnSubscriptionAvailable;
            } else {
              return IndeedLicenseModalType.indeedSubscriptionWithdrawn;
            }
          }
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (!filterIndeedCandidatesBasedOnUserLicense) return;

    const [lastAudit] = audit;

    if (!isFetchingUpdatedLicenses && currentUser && lastAudit) {
      const lastAuditLocalStorage = localStorage.getItem(LAST_AUDIT_INDEED_LS);
      const lastAuditLSDate = lastAuditLocalStorage ? new Date(lastAuditLocalStorage) : null;

      if (!lastAuditLSDate || lastAuditLSDate < new Date(lastAudit.created_at)) {
        const type = verifyCurrentUserLicenseGranted({ currentUserId: currentUser.id, lastAudit, availableLicenses });

        if (type) {
          localStorage.setItem(LAST_AUDIT_INDEED_LS, lastAudit.created_at);
          handleOpen(type);
        }
      }
    }
  }, [
    filterIndeedCandidatesBasedOnUserLicense,
    audit,
    currentUser,
    availableLicenses,
    isFetchingUpdatedLicenses,
    verifyCurrentUserLicenseGranted,
  ]);

  useEffect(() => {
    if (!filterIndeedCandidatesBasedOnUserLicense) return;

    if (currentUser && !isFetchingUpdatedLicenses && dataUpdatedAt > 0) {
      const alreadySeen = localStorage.getItem(FIRST_INDEED_MODAL_LS);

      if (!alreadySeen) {
        validateIndeedStatus().then((type) => {
          if (type === IndeedLicenseModalType.noIndeedSubscriptionConnectedAdmin) {
            userClient
              .notifyUserIndeedLicenseStatusChanged({
                user_id: currentUser.id,
                email_type: IndeedLicenseEmailType.noIndeedSubscriptionConnectedAdmin,
              })
              .catch((e) => {
                console.error(e);
                logger.error({
                  error: e,
                  customMessage: 'Error when notifying users about indeed license assignment changed',
                });
              });
          }
        });
      }

      localStorage.setItem(FIRST_INDEED_MODAL_LS, 'seen');
    }
  }, [filterIndeedCandidatesBasedOnUserLicense, type, currentUser, isFetchingUpdatedLicenses, dataUpdatedAt]);

  const handleCloseModal = () => {
    setIsVisible(false);

    if (
      [
        IndeedLicenseModalType.indeedSubscriptionConnected,
        IndeedLicenseModalType.indeedSubscriptionConnectedAdmin,
      ].includes(type)
    ) {
      window.location.reload();
    }
  };

  const handleOpen = useCallback<IndeedModalHandlers['open']>((callback) => {
    const type = typeof callback === 'function' ? callback(IndeedLicenseModalType) : callback;

    setType(type);
    setIsVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const validateIndeedStatus = useCallback(async () => {
    if (!currentUser) return;

    const hasLicenses = updatedLicenses.length > 0;
    const hasAvailableLicenses = availableLicenses.length > 0;
    const isAdmin = currentUser.role === UserRoles.Admin;
    let modalType: IndeedLicenseModalType | undefined = undefined;

    if (!hasLicenses) {
      modalType = isAdmin
        ? IndeedLicenseModalType.noIndeedSubscriptionConnectedAdmin
        : IndeedLicenseModalType.noIndeedSubscriptionConnected;
    } else {
      if (currentUser.indeed_license?.status === 'EXPIRED') {
        modalType = hasAvailableLicenses
          ? IndeedLicenseModalType.indeedSubscriptionExpiredWithAvailable
          : IndeedLicenseModalType.indeedSubscriptionExpired;
      } else {
        modalType = hasAvailableLicenses
          ? IndeedLicenseModalType.indeedSubscriptionAvailable
          : IndeedLicenseModalType.noIndeedSubscriptionAvailable;
      }
    }

    if (modalType) {
      handleOpen(modalType);
    }

    return modalType;
  }, [currentUser, indeedConfig, updatedLicenses, availableLicenses, audit]);

  const handleRequestLogin = async ({ shouldIgnoreIndeedLogin } = { shouldIgnoreIndeedLogin: false }) => {
    if (!currentUser) return;

    const hasAvailableLicenses = availableLicenses.length > 0;
    const isAdmin = currentUser.role === UserRoles.Admin;

    if (!shouldIgnoreIndeedLogin) {
      await new Promise<void>((res) => {
        const onFocus = () => [window.removeEventListener('focus', onFocus), res()];

        window.addEventListener('focus', onFocus);

        window.open(LOGIN_TO_INDEED_LINK, '_blank', 'noopener');
      });
    }

    await queryClient.invalidateQueries([QueryKey.useIndeedCommonModelQuery], { exact: false });
    const newIndeedConfig = indeedConfig.getCurrentValue();

    if (!newIndeedConfig.isLoggedIn) {
      return handleOpen((t) => (isAdmin ? t.noIndeedSubscriptionConnectedAdmin : t.noIndeedSubscriptionConnected));
    }

    const loggedInLicense = updatedLicenses.find((l) => l.indeed_license_id === newIndeedConfig.email);

    if (!loggedInLicense) {
      return handleOpen((t) =>
        hasAvailableLicenses ? t.noIndeedActiveSubscriptionWithAvailable : t.noIndeedActiveSubscription,
      );
    }

    if (loggedInLicense.user && loggedInLicense.user.id !== currentUser.id) {
      return handleOpen((t) =>
        hasAvailableLicenses ? t.indeedSubscriptionAvailable : t.noIndeedSubscriptionAvailable,
      );
    }

    if (loggedInLicense.user === null) {
      await indeedAssociateLicenseMutation.mutateAsync({ license_id: loggedInLicense.id });
    }

    try {
      if (currentUser && isAdmin) {
        userClient.notifyUserIndeedLicenseStatusChanged({
          user_id: currentUser.id,
          email_type: IndeedLicenseEmailType.indeedSubscriptionConnectedAdmin,
        });
      }
    } catch (e) {
      console.error(e);
      logger.error({ error: e, customMessage: 'Error when notifying users about indeed license assignment changed' });
    }

    return handleOpen((t) => (isAdmin ? t.indeedSubscriptionConnectedAdmin : t.indeedSubscriptionConnected));
  };

  const handlers = useMemo<IndeedModalHandlers>(
    () => ({ open: handleOpen, close: handleClose, validateIndeedStatus }),
    [validateIndeedStatus],
  );

  const modal = filterIndeedCandidatesBasedOnUserLicense ? (
    <IndeedLicenseModal
      isVisible={isVisible}
      onRequestLogin={handleRequestLogin}
      onRequestClose={handleCloseModal}
      type={type}
      assignedEmail={currentUser?.indeed_license?.indeed_license_id}
      loggedInEmail={indeedConfig.email ?? ''}
      availableEmails={availableLicenses.map((l) => l.indeed_license_id)}
    />
  ) : null;

  return [handlers, modal] as const;
};
