import { routes } from 'config/routes';
import { useNavigate } from 'react-router-dom';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { useContextSelector } from 'use-context-selector';

export const useContainer = () => {
  const navigate = useNavigate();
  const navigateTo = (route: string) => navigate(route);
  const selectedCampaign = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign);

  const verifyAndNavigateToSearchRoute = () => {
    if (!window.location.href.includes('/search') && !window.location.href.includes('/home')) {
      const url = routes.search({ campaignId: selectedCampaign?.id });

      navigate(url, { replace: true });
    }
  };

  return {
    navigateTo,
    verifyAndNavigateToSearchRoute,
  };
};
