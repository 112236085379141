import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { queryClient } from 'config/queryClient';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { CampaignFacets, CampaignJobboard } from './SelectedCampaignContext.types';

export const useFacets = (id: number | undefined) => {
  const pageContext = usePageContext();

  const { data, isFetching } = useQuery<CampaignFacets>(
    [QueryKey.selectedCampaignContextUseFacets, id],
    async () => {
      if (id === undefined) throw Error('Campaign id undefined');

      const { data } = await campaignsClient.getFacets(id);

      const { buckets } = data.aggregations.source_facets.source_facet;

      return buckets.reduce((acc, { key, doc_count }) => ({ ...acc, [key]: doc_count }), initialFacetsState);
    },
    { enabled: id !== undefined && pageContext === PageContext.Campaign },
  );

  return useMemo(() => ({ facets: data || initialFacetsState, isLoading: isFetching }), [data, isFetching]);
};

export const initialFacetsState: CampaignFacets = {
  [CampaignJobboard.Database]: 0,
  [CampaignJobboard.Debanensite]: 0,
  [CampaignJobboard.Indeed]: 0,
  [CampaignJobboard.Jobbird]: 0,
  [CampaignJobboard.Linkedin]: 0,
  [CampaignJobboard.Monsterboard]: 0,
  [CampaignJobboard.NationaleVacaturebank]: 0,
  [CampaignJobboard.Resumeio]: 0,
  [CampaignJobboard.Techniekwerkt]: 0,
  [CampaignJobboard.Uitzendbureau]: 0,
  [CampaignJobboard.Werknl]: 0,
  [CampaignJobboard.StudentJob]: 0,
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // [CampaignJobboard.Werkzoeken]: 0,
};

export const setFacets = (id: number | undefined, callback: (data: CampaignFacets) => CampaignFacets) => {
  queryClient.setQueryData([QueryKey.selectedCampaignContextUseFacets, id], (data: CampaignFacets | undefined) => {
    if (!data) throw Error('Set facets undefined data');

    return callback(data);
  });
};
