import SearchInput from 'components/ui/organisms/SearchInput';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { LandingPageContext } from 'shared/contexts/LandingPageContext';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useContextSelector } from 'use-context-selector';
import { useStyles } from './LandingPageOverlay.styles';

export const LandingPageOverlay = () => {
  const { isOpen: isSidebarOpen } = useContext(SidebarOverlayFixedContext);
  const setIsShowingLandingPage = useContextSelector(LandingPageContext, (state) => state.setIsShowingLandingPage);
  const isShowingLandingPage = useContextSelector(LandingPageContext, (state) => state.isShowingLandingPage);
  const classes = useStyles({ isSidebarOpen });
  const [isHome, setIsHome] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHome(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isHome) {
      const timer = setTimeout(() => {
        setIsShowingLandingPage(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isHome]);

  return (
    <div css={classes.root}>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isHome ? 1 : 0 }}
        transition={{ duration: 1 }}
        css={classes.background}
        style={{
          position: 'absolute',
          zIndex: isShowingLandingPage ? 10 : 0,
        }}
      />

      <motion.div
        initial={{
          top: isHome ? 'calc(50% - 23px)' : '14px',
          width: '100%',
        }}
        animate={{
          top: isHome ? 'calc(50% - 23px)' : '14px',
          width: isHome ? '100%' : `calc(100% - 119px - ${isSidebarOpen ? 185 : 0}px)`,
        }}
        transition={{ duration: 0.35 }}
        style={{
          position: 'absolute',
          zIndex: 10,
        }}>
        <SearchInput isExpanded={isHome} />
      </motion.div>
    </div>
  );
};

export default LandingPageOverlay;
